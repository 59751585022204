import styled, { createGlobalStyle, keyframes } from "styled-components";

const GlobalStyle = createGlobalStyle`
  *, ::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  html {
    width: 100%;
    height: 100%;
  }
  body {
    width: 100%;
    height: 100%;
    background: #3530F0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const partialRotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(220deg); }
`;

const bounceRotate = keyframes`
  0% { transform: rotate(-140deg); }
  50% { transform: rotate(-160deg); }
  100% { transform: rotate(140deg); }
`;

const Loader = styled.div`
  height: 70px;
  width: 70px;
  animation: ${rotate} 4.8s linear infinite;

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 70px;
    width: 70px;
    clip: rect(0, 70px, 70px, 35px);
    animation: ${partialRotate} 1.2s linear infinite;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 70px;
      width: 70px;
      clip: rect(0, 70px, 70px, 35px);
      border: ${(props) =>
        props.themeColor ? "3px solid #fff" : "3px solid black"};
      border-radius: 50%;
      animation: ${bounceRotate} 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }
  }
`;

const CircleLoader = ({themeColor}) => (
  <div
    style={{
      minHeight: "450px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      minWidth: "800px"
    }}
  >
    <GlobalStyle />
    <Loader themeColor={themeColor}>
      <span />
    </Loader>
  </div>
);

export default CircleLoader;
