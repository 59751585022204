import CryptoJS from "crypto-js";

const secretKeyMain = "rmAdminSecretKey";
export const decryptData = (encryptedData) => {
  if (!encryptedData) {
    return null; // Return null if no encrypted data is available
  }

  try {
    // Decrypt data using AES and secret key
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKeyMain);
    const decryptedData = bytes?.toString(CryptoJS.enc.Utf8);

    // If the result is empty, return null to prevent JSON parsing errors
    if (!decryptedData) {
      return null;
    }

    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Decryption error:", error);
    return null; // Return null if there's a decryption error
  }
};
export function formatFileSize(bytes) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(2));

  return `${formattedSize} ${sizes[i]}`;
}

const secretKey = "yourSecretKey";
export const encryptedData = (dataToEncrypt) => {
  return CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();
};
export const decryptedData = (dataToEncrypt) => {
  var bytes = CryptoJS.AES.decrypt(dataToEncrypt, secretKey);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export function removeDuplicatesById(arr) {
  const seenIds = new Set();
  return arr.filter((obj) => {
    if (seenIds.has(obj._id)) {
      return false; // Duplicate _id, filter it out
    }
    seenIds.add(obj._id);
    return true; // Unique _id, keep it
  });
}

export function combineDateAndTime(dateInput, timeInput) {
  // const date = new Date(dateInput);
  // const [hours, minutes] = timeInput?.split(":")?.map(Number);

  // // Assuming the input date is in local time, adjust it to UTC based on local offset
  // // This is crucial if the input time is meant to be treated as local time
  // const localOffset = date?.getTimezoneOffset() * 60000; // Offset in milliseconds
  // const adjustedDate = new Date(date?.getTime() + localOffset); // Adjust to UTC

  // adjustedDate?.setHours(hours, minutes, 0, 0); // Set time, assuming the timeInput is in local time

  // // Convert to ISO string
  // return adjustedDate.toISOString();
  const [hours, minutes] = timeInput?.split(":")?.map(Number);

  dateInput.setHours(hours, minutes, 0, 0);

  const pad = (num) => num?.toString()?.padStart(2, "0");

  const localFormattedDate = `${dateInput?.getFullYear()}-${pad(
    dateInput?.getMonth() + 1
  )}-${pad(dateInput?.getDate())}T${pad(dateInput?.getHours())}:${pad(
    dateInput?.getMinutes()
  )}`;

  return localFormattedDate;
}

export function convertTimeFormat(timeStr) {
  let [hh, mm, ss] = timeStr?.split(":");
  return `${parseInt(hh)}h ${parseInt(mm)}m`;
}
export function convertTimeFormatMin(timeStr) {
  let [hh, mm, ss] = timeStr?.split(":");
  return `${parseInt(mm)} Min.`;
}

export function formatDate(inputDate) {
  const date = new Date(inputDate);

  // Adjust the time zone if needed (e.g., subtracting or adding hours)
  // Here, we'll just subtract 6 hours to match your desired time
  date.setHours(date.getHours() - 6); // Adjust as needed

  // Extract the components of the date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Construct the final formatted date
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

  return formattedDate;
}
export function getWeekOfMonth(date) {
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const startDayOfMonth = startOfMonth.getDay();
  return Math.ceil((date.getDate() + startDayOfMonth) / 7);
}
export function checkImageDimensionsClient(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      if (img.width === 576 && img.height === 189) {
        resolve(true);
      } else {
        reject("Image must be exactly W.576 x H.180 pixels");
      }
    };
    img.onerror = () => {
      reject("Error loading image");
    };
    img.src = URL.createObjectURL(file);
  });
}
export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKeyMain).toString();
};

export const calculateFun = (val1, val2, val3) => {
  return (val1) / 100;
};
export const getLastTwoYearsDates = () => {
  const currentDate = new Date(); // Today's date
  const startDate = currentDate.toISOString().split("T")[0]; // Format as YYYY-MM-DD

  // Subtract 2 years from the current date
  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
  const endDate = twoYearsAgo.toISOString().split("T")[0]; // Format as YYYY-MM-DD

  return {
    startDate,
    endDate,
  };
};

export const formatTime = (time) => {
  //formarting duration of video
  if (isNaN(time)) {
    return "00:00";
  }

  const date = new Date(time * 1000);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");
  if (hours) {
    //if video have hours
    return `${hours}:${minutes.toString().padStart(2, "0")} `;
  } else return `${minutes}:${seconds}`;
};

export const countryValidationData =[
  { "country": "Afghanistan", "code": "af", "dialCode": "+93", "length": 9 },
  { "country": "Albania", "code": "al", "dialCode": "+355", "length": 9 },
  { "country": "Algeria", "code": "dz", "dialCode": "+213", "length": 9 },
  { "country": "Andorra", "code": "ad", "dialCode": "+376", "length": 6 },
  { "country": "Angola", "code": "ao", "dialCode": "+244", "length": 9 },
  { "country": "Argentina", "code": "ar", "dialCode": "+54", "length": 10 },
  { "country": "Armenia", "code": "am", "dialCode": "+374", "length": 8 },
  { "country": "Australia", "code": "au", "dialCode": "+61", "length": 9 },
  { "country": "Austria", "code": "at", "dialCode": "+43", "length": 10 },
  { "country": "Azerbaijan", "code": "az", "dialCode": "+994", "length": 9 },
  { "country": "Bahrain", "code": "bh", "dialCode": "+973", "length": 8 },
  { "country": "Bangladesh", "code": "bd", "dialCode": "+880", "length": 10 },
  { "country": "Belarus", "code": "by", "dialCode": "+375", "length": 9 },
  { "country": "Belgium", "code": "be", "dialCode": "+32", "length": 9 },
  { "country": "Belize", "code": "bz", "dialCode": "+501", "length": 7 },
  { "country": "Benin", "code": "bj", "dialCode": "+229", "length": 8 },
  { "country": "Bhutan", "code": "bt", "dialCode": "+975", "length": 8 },
  { "country": "Bolivia", "code": "bo", "dialCode": "+591", "length": 8 },
  { "country": "Bosnia and Herzegovina", "code": "ba", "dialCode": "+387", "length": 8 },
  { "country": "Brazil", "code": "br", "dialCode": "+55", "length": 11 },
  { "country": "Bulgaria", "code": "bg", "dialCode": "+359", "length": 9 },
  { "country": "Burkina Faso", "code": "bf", "dialCode": "+226", "length": 8 },
  { "country": "Burundi", "code": "bi", "dialCode": "+257", "length": 8 },
  { "country": "Cambodia", "code": "kh", "dialCode": "+855", "length": 9 },
  { "country": "Cameroon", "code": "cm", "dialCode": "+237", "length": 9 },
  { "country": "Canada", "code": "ca", "dialCode": "+1", "length": 10 },
  { "country": "China", "code": "cn", "dialCode": "+86", "length": 11 },
  { "country": "Colombia", "code": "co", "dialCode": "+57", "length": 10 },
  { "country": "Costa Rica", "code": "cr", "dialCode": "+506", "length": 8 },
  { "country": "Croatia", "code": "hr", "dialCode": "+385", "length": 9 },
  { "country": "Cuba", "code": "cu", "dialCode": "+53", "length": 8 },
  { "country": "Cyprus", "code": "cy", "dialCode": "+357", "length": 8 },
  { "country": "Czech Republic", "code": "cz", "dialCode": "+420", "length": 9 },
  { "country": "Denmark", "code": "dk", "dialCode": "+45", "length": 8 },
  { "country": "Egypt", "code": "eg", "dialCode": "+20", "length": 10 },
  { "country": "Estonia", "code": "ee", "dialCode": "+372", "length": 7 },
  { "country": "Ethiopia", "code": "et", "dialCode": "+251", "length": 9 },
  { "country": "Finland", "code": "fi", "dialCode": "+358", "length": 10 },
  { "country": "France", "code": "fr", "dialCode": "+33", "length": 9 },
  { "country": "Germany", "code": "de", "dialCode": "+49", "length": 11 },
  { "country": "Greece", "code": "gr", "dialCode": "+30", "length": 10 },
  { "country": "India", "code": "in", "dialCode": "+91", "length": 10 },
  { "country": "Indonesia", "code": "id", "dialCode": "+62", "length": 10 },
  { "country": "Iran", "code": "ir", "dialCode": "+98", "length": 10 },
  { "country": "Iraq", "code": "iq", "dialCode": "+964", "length": 10 },
  { "country": "Ireland", "code": "ie", "dialCode": "+353", "length": 9 },
  { "country": "Israel", "code": "il", "dialCode": "+972", "length": 9 },
  { "country": "Italy", "code": "it", "dialCode": "+39", "length": 10 },
  { "country": "Japan", "code": "jp", "dialCode": "+81", "length": 10 },
  { "country": "Kenya", "code": "ke", "dialCode": "+254", "length": 9 },
  { "country": "Malaysia", "code": "my", "dialCode": "+60", "length": 10 },
  { "country": "Nepal", "code": "np", "dialCode": "+977", "length": 10 },
  { "country": "Netherlands", "code": "nl", "dialCode": "+31", "length": 9 },
  { "country": "Nigeria", "code": "ng", "dialCode": "+234", "length": 10 },
  { "country": "Norway", "code": "no", "dialCode": "+47", "length": 8 },
  { "country": "Pakistan", "code": "pk", "dialCode": "+92", "length": 10 },
  { "country": "Philippines", "code": "ph", "dialCode": "+63", "length": 10 },
  { "country": "Russia", "code": "ru", "dialCode": "+7", "length": 10 },
  { "country": "Saudi Arabia", "code": "sa", "dialCode": "+966", "length": 9 },
  { "country": "Singapore", "code": "sg", "dialCode": "+65", "length": 8 },
  { "country": "South Africa", "code": "za", "dialCode": "+27", "length": 9 },
  { "country": "South Korea", "code": "kr", "dialCode": "+82", "length": 10 },
  { "country": "Spain", "code": "es", "dialCode": "+34", "length": 9 },
  { "country": "Sri Lanka", "code": "lk", "dialCode": "+94", "length": 9 },
  { "country": "Sweden", "code": "se", "dialCode": "+46", "length": 10 },
  { "country": "Switzerland", "code": "ch", "dialCode": "+41", "length": 9 },
  { "country": "Taiwan", "code": "tw", "dialCode": "+886", "length": 9 },
  { "country": "Thailand", "code": "th", "dialCode": "+66", "length": 9 },
  { "country": "Turkey", "code": "tr", "dialCode": "+90", "length": 10 },
  { "country": "United Arab Emirates", "code": "ae", "dialCode": "+971", "length": 9 },
  { "country": "United Kingdom", "code": "gb", "dialCode": "+44", "length": 10 },
  { "country": "United States", "code": "us", "dialCode": "+1", "length": 10 },
  { "country": "Vietnam", "code": "vn", "dialCode": "+84", "length": 10 }
]