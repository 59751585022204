import React, { useState, useRef, useEffect } from "react";
import * as CS from "../styles/ControlStyled";
import muteIcon from "../assets/images/mutes.png";
import pause from "../assets/images/pause.png";
import play from "../assets/images/play.png";
import unmute from "../assets/images/unmute.png";
import fullScreen from "../assets/images/fullScreen.png";
import styled from "styled-components";

const Control = ({
  playing,
  played,
  onVolumeChange,
  volume,

  duration,
  currentTime,
  controlRef,
  onPlay,
  onPause,
  handleMute,
  isMuted,
  seek,
  handleSeekChange,
  qualities,
  handleQualityChange,
  currentQuality,
  toggleFullScreen,
  handleProgress,
  isFullScreen,
  isCourseConent
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isQuality, setIsQuality] = useState(false);
  const profileRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsQuality(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  return (
    <CS.ControlsContainer isFullScreen={isFullScreen} ref={controlRef} isCourseConent={isCourseConent || false}>
      <CS.MidContainer>
        {playing ? (
          <CS.IconButton onClick={onPause}>
            <img src={pause} alt="" />
          </CS.IconButton>
        ) : (
          <CS.IconButton onClick={onPlay}>
            <img src={play} alt="" />
          </CS.IconButton>
        )}
      </CS.MidContainer>
      <CS.ParentControlBox>
      <CS.ControlBox>
        <CS.InnerControlBox>
          <CS.InnerControls visible={isVisible}>
            {playing ? (
              <CS.IconButton onClick={onPause}>
                <img src={pause} alt="" />
              </CS.IconButton>
            ) : (
              <CS.IconButton onClick={onPlay}>
                <img src={play} alt="" />
              </CS.IconButton>
            )}
            <CS.IconButtonsContainer>
              <CS.IconButtonsContainers
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
              >
                <CS.IconButtons onClick={handleMute}>
                  <img src={isMuted ? muteIcon : unmute} alt="" />
                </CS.IconButtons>
                <CS.VolumeSlider
                  visible={isVisible}
                  onChange={onVolumeChange}
                  value={volume}
                />
              </CS.IconButtonsContainers>
              <CS.Seektime visible={isVisible}>
                <p>
                  {currentTime} / {duration}
                </p>
              </CS.Seektime>
            </CS.IconButtonsContainer>
          </CS.InnerControls>
          <CS.SliderContainer isFullScreen={isFullScreen} visible={isVisible}>
            <div style={{ width: "100%" }}>
              <CS.SeekSlider
                value={seek}
                onChange={handleSeekChange}
                min={0}
                max={100}
              />
              {/* <input
                type="range"
                value={seek}
                min={0}
                max={100}
                onChange={handleSeekChange}
                style={{ width: "100%" }}
              /> */}
            </div>
          </CS.SliderContainer>
        </CS.InnerControlBox>
        <CS.BottomContainer>
          <img onClick={toggleFullScreen} src={fullScreen} alt="" />
        </CS.BottomContainer>
      </CS.ControlBox>
      </CS.ParentControlBox>
    </CS.ControlsContainer>
  );
};

export default Control;
