import React, { useState, Suspense, useEffect, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { publicRoutes, privateRoutes } from "./routes";
import { useSelector, useDispatch } from "react-redux";
import englishLang from "../src/utils/Language/english.json";
import * as CS from "./styles/CommonStyled";
import * as LE from "./styles/LoginStyled";
import Navbar from "./components/Navbar/Navbar";
import { RMlogin } from "./utils/api";
import bannerSample from "./assets/images/bannerSample.png";
import axios, { updateAccessToken } from "./utils/axios";
import Sidebar from "./components/Sidebar/Sidebar";
import backVideo from "./assets/images/backVideo.mp4";
import {
  setAccessToken,
  setClientId,
  setErrorPop,
  setIsOnBoardingSet,
  setProfileData,
  setSelectLangJson,
} from "./store/action";
import { Modal } from "./components/PopUp/Modal";
import ModalPo from "./components/Modal/Modal";
import * as SEC from "./styles/SectionStyled";
import warn from "./assets/images/warns.png";
import CourseDetail from "./pages/private/CourseDetail/CourseDetail";
import CourseContent from "./pages/private/CourseDetail/CourseContent";
import ManageLearners from "./pages/private/CourseDetail/ManageLearners";
import PolicyDocument from "./pages/private/CourseDetail/PolicyDocument";
import ReqAdditional from "./pages/private/CourseDetail/ReqAdditional";
import Renew from "./pages/private/CourseDetail/Renew";
import Committee from "./pages/private/CourseDetail/Committee";
import AddLearner from "./pages/private/CourseDetail/AddLearner";
import LearnerView from "./pages/private/CourseDetail/LearnerView";
import AddPolicy from "./pages/private/CourseDetail/AddPolicy";
import AddCommittee from "./pages/private/CourseDetail/AddCommittee";
import LayoutPage from "./Layout";
import demoImage1 from "./assets/images/onBoarding/demoImage1.png";
import demoImage2 from "./assets/images/onBoarding/demoImage2.png";
import demoImage3 from "./assets/images/onBoarding/demoImage3.png";
import demoImage4 from "./assets/images/onBoarding/demoImage4.png";
import demoImage5 from "./assets/images/onBoarding/demoImage5.png";
import demoImage6 from "./assets/images/onBoarding/demoImage6.png";
import demoImage7 from "./assets/images/onBoarding/demoImage7.png";
import demoImage8 from "./assets/images/onBoarding/demoImage8.png";
import * as AS from "./styles/ArrivalStyled";
import { Rating } from "react-simple-star-rating";
import { colorProvider } from "./utils/StylePropertiy";
const Reports = lazy(() => import("./pages/private/Reports"));
const CourseReports = lazy(() =>
  import("./pages/private/Reports/SubReports/CourseReports")
);
const CourseShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/CourseShedule")
);
const CourseWiseAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/CourseWiseAddShedule")
);
const CompletionReports = lazy(() =>
  import("./pages/private/Reports/SubReports/CompletionReports")
);
const CompletionShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/CompletionShedule")
);
const CompletionWiseAddShedule = lazy(() =>
  import(
    "./pages/private/Reports/SubReports/AddShedule/CompletionWiseAddShedule"
  )
);
const DepartmentReports = lazy(() =>
  import("./pages/private/Reports/SubReports/DepartmentReports")
);
const DepartmentShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/DepartmentShedule")
);
const DepartmentAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/DepartmentAddShedule")
);
const IndustryReports = lazy(() =>
  import("./pages/private/Reports/SubReports/LocationReports")
);
const IndustryShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/LocationShedule")
);
const IndustryAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/LocationAddShedule")
);
const RollsReports = lazy(() =>
  import("./pages/private/Reports/SubReports/ReportingReports")
);
const RollOutShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/ReportingShedule")
);
const RollOutAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/ReportingAddShedule")
);
const ProgressReports = lazy(() =>
  import("./pages/private/Reports/SubReports/LicensesReports")
);
const ProgressShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/LicensesShedule")
);
const ProgressAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/LicensesAddShedule")
);
const QuizReports = lazy(() =>
  import("./pages/private/Reports/SubReports/QuizReports")
);
const QuizShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/QuizShedule")
);
const QuizAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/QuizAddShedule")
);
const AuditReports = lazy(() =>
  import("./pages/private/Reports/SubReports/YearWiseReports")
);
const AuditShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/YearWiseShedule")
);
const AuditAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/YearWiseAddShedule")
);
const FeedbackReports = lazy(() =>
  import("./pages/private/Reports/SubReports/FeedbackReports")
);
const FeedbackShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/FeedbackShedule")
);
const FeedbackAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/FeedbackAddShedule")
);
const AddandLearnerDeleteReports = lazy(() =>
  import("./pages/private/Reports/SubReports/AddandLearnerDeleteReports")
);
const AddLearnerShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/AddLearnerShedule")
);
const AddLearnerAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/AddLearnerAddShedule")
);
const MasterReport = lazy(() =>
  import("./pages/private/Reports/SubReports/MasterReports")
);
const MasterShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/MasterShedule")
);
const MasterAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/MasterAddShedule")
);
const EntryReports = lazy(() =>
  import("./pages/private/Reports/SubReports/EntryReports")
);
const EntryWiseShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/Shedule/EntryWiseShedule")
);
const EntryWiseAddShedule = lazy(() =>
  import("./pages/private/Reports/SubReports/AddShedule/EntryWiseAddShedule")
);

const Feedback = lazy(() => import("./pages/private/Feedback/Feedback"));
const CoursesDetailComp = lazy(() =>
  import("./pages/private/CourseDetail/CoursesDetailComp")
);

const PublicRoute = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state?.isDark);
  const [bannerData, setBannerData] = useState([]);
  const [bannerConfigData, setBannerConfigData] = useState([]);
  const getBanner = async () => {
    let url = RMlogin.getBanner;
    try {
      const { data } = await axios.get(url);
      setBannerData(data.data[0]);
      setBannerConfigData(data?.config);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);
  return (
    <Routes>
      {publicRoutes.map((val, ind) => {
        return (
          <Route
            exact
            key={ind}
            path={val.path}
            element={
              <Suspense>
                <CS.MainContainer>
                  <CS.InnerContainer>
                    <CS.BackContainer>
                      <video
                        className="video-background"
                        width="320"
                        height="240"
                        autoPlay
                        loop
                        muted
                      >
                        <source src={backVideo} type="video/mp4" />
                      </video>
                      <val.component
                        navigate={navigate}
                        location={location}
                        dispatch={dispatch}
                      />
                      <LE.BannerImageBox>
                        <LE.BannerHead>
                          <LE.BannerTitle
                            style={{
                              width: bannerConfigData?.googleReviews
                                ? "75%"
                                : "100%",
                            }}
                          >
                            {bannerData?.showTextStatus
                              ? `${bannerData?.showText}`
                              : ""}
                          </LE.BannerTitle>
                          {bannerConfigData?.googleReviews ? (
                            <LE.ReviewBox>
                              <LE.ReviewBoxOne>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "5px",
                                    cursor: "pointer",
                                  }}
                                >
                                  {/* Render 5 stars with halves */}
                                  {[1, 2, 3, 4, 5].map((starIndex) => (
                                    <div
                                      key={starIndex}
                                      style={{
                                        position: "relative",
                                        display: "inline-block",
                                      }}
                                    >
                                      {/* Left Half */}
                                      <div
                                        style={{
                                          position: "absolute",
                                          width: "50%",
                                          height: "100%",
                                          left: 0,
                                          zIndex: 1,
                                        }}
                                      ></div>

                                      {/* Right Half */}
                                      <div
                                        style={{
                                          position: "absolute",
                                          width: "50%",
                                          height: "100%",
                                          right: 0,
                                          zIndex: 1,
                                        }}
                                      ></div>

                                      {/* Full Star Icon */}
                                      <i
                                        className={`fa fa-star${
                                          bannerConfigData?.googleReviewsRating >=
                                          starIndex
                                            ? ""
                                            : bannerConfigData?.googleReviewsRating >=
                                              starIndex - 0.5
                                            ? "-half-full"
                                            : "-o"
                                        } fa-x`}
                                        style={{
                                          color:
                                            bannerConfigData?.googleReviewsRating >=
                                            starIndex - 0.5
                                              ? "#f5c518"
                                              : "#ccc",
                                        }}
                                      ></i>
                                    </div>
                                  ))}
                                </div>
                                <p>{bannerConfigData?.googleReviewsRating}</p>
                              </LE.ReviewBoxOne>
                              <LE.ReviewBoxTwo>
                                <p>
                                  from {bannerConfigData?.googleReviewsNumber}+
                                  reviews
                                </p>
                              </LE.ReviewBoxTwo>
                            </LE.ReviewBox>
                          ) : (
                            ""
                          )}
                        </LE.BannerHead>
                        {bannerData?.showBannerImageStatus ? (
                          <LE.BannerImage
                            href={bannerData?.bannerLink}
                            target="_blank"
                          >
                            <img src={bannerData?.bannerImage} alt="" />
                          </LE.BannerImage>
                        ) : (
                          ""
                        )}
                      </LE.BannerImageBox>
                    </CS.BackContainer>
                  </CS.InnerContainer>
                </CS.MainContainer>
              </Suspense>
            }
          />
        );
      })}
      <Route
        exact
        path="/client-admin/client-survey-form"
        element={<Feedback />}
      ></Route>
      <Route path="/*" element={<Navigate to="/client-admin" />} />
    </Routes>
  );
};
const PrivateRoute = ({ accessToken }) => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.userData);
  const theme = useSelector((state) => state?.isDark);
  const courseId = useSelector((state) => state?.courseId);
  const errorPop = useSelector((state) => state?.errorPop);
  const errorPopMsg = useSelector((state) => state?.errorPopMsg);
  const selectLang = useSelector((state) => state?.selectLang);
  const handleClose = () => {
    dispatch(setErrorPop(false));
  };
  console.log(state, "state statestate statestate");
  const [localState, setLocalState] = useState(englishLang?.client_admin);
  console.log(localState, "localState localState localState");

  console.log(selectLang, "selectLang selectLang");
  const languages = useSelector((state) => state?.languages);
  console.log(languages, "languages languages");

  useEffect(() => {
    if (selectLang && languages) {
      let filterLangJson = languages?.find((val) => val._id === selectLang);
      if (filterLangJson) {
        dispatch(
          setSelectLangJson(JSON.parse(filterLangJson?.acexcel_to_json))
        );
      }
    }
  }, [selectLang, languages]);
  return (
    <>
      <Routes>
        <Route path="/" element={<LayoutPage />}>
          {privateRoutes.map((val, ind) => {
            return (
              <Route
                exact
                key={ind}
                path={val.path}
                element={
                  <Suspense>
                    <val.component
                      stateData={state}
                      navigate={navigate}
                      location={location}
                      dispatch={dispatch}
                      theme={theme}
                      localState={localState}
                      accessToken={accessToken}
                      courseId={courseId}
                    />
                  </Suspense>
                }
              />
            );
          })}
          <Route
            exact
            path="/client-admin/courses-manage"
            element={
              <Suspense>
                <CoursesDetailComp
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          >
            <Route index element={<Navigate to="courses-content" />} />
            <Route
              path="courses-detail"
              element={
                <CourseDetail
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="courses-content"
              element={
                <CourseContent
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="manage-learners"
              element={
                <ManageLearners
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="policy-document"
              element={
                <PolicyDocument
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="request-additional-learners"
              element={
                <ReqAdditional
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="renew"
              element={
                <Renew
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="committee"
              element={
                <Committee
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="add-learner"
              element={
                <AddLearner
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="learner-view"
              element={
                <LearnerView
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="add-policy"
              element={
                <AddPolicy
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
            <Route
              path="add-committee"
              element={
                <AddCommittee
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              }
            />
          </Route>
          <Route
            exact
            path="/client-admin/reports"
            element={
              <Suspense>
                <Reports
                  stateData={state}
                  navigate={navigate}
                  location={location}
                  dispatch={dispatch}
                  theme={theme}
                  localState={localState}
                  accessToken={accessToken}
                  courseId={courseId}
                />
              </Suspense>
            }
          >
            <Route index element={<Navigate to="master-reports" />} />
            <Route
              path="master-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <MasterReport
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="master-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <MasterShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="master-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <MasterAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="entry-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <EntryReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="entry-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <EntryWiseShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="entry-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <EntryWiseAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="course-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <CourseReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="course-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <CourseShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="course-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <CourseWiseAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="completion-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <CompletionReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="completion-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <CompletionShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="completion-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <CompletionWiseAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="department-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <DepartmentReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="department-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <DepartmentShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="department-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <DepartmentAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="location-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <IndustryReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="location-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <IndustryShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="location-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <IndustryAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="reporting-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <RollsReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="reporting-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <RollOutShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="reporting-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <RollOutAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="licenses-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <ProgressReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="licenses-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <ProgressShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="licenses-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <ProgressAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="year-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <AuditReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="year-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <AuditShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="year-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <AuditAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="quiz-analysis-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <QuizReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="quiz-analysis-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <QuizShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="quiz-analysis-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <QuizAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="sub-entity-reports"
              element={
                <Suspense fallback={<div></div>}>
                  <FeedbackReports
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="sub-entity-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <FeedbackShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
            <Route
              path="sub-entity-add-shedule"
              element={
                <Suspense fallback={<div></div>}>
                  <FeedbackAddShedule
                    stateData={state}
                    navigate={navigate}
                    location={location}
                    dispatch={dispatch}
                    theme={theme}
                    localState={localState}
                    accessToken={accessToken}
                    courseId={courseId}
                  />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route path="/*" element={<Navigate to="/client-admin/dashboard" />} />
      </Routes>
      <Modal
        isOpen={errorPop}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxSo>
              <img src={theme ? warn : warn} alt="danger" />
            </SEC.SuccessIconBoxSo>
          </>
        }
        content={
          <SEC.InnerSectionSuccess>
            <SEC.TitleSo themeColor={theme}>Warning!</SEC.TitleSo>
            {console.log(errorPopMsg, "msg")}
            <SEC.Subtitle themeColor={theme}>{errorPopMsg}</SEC.Subtitle>
            <SEC.BtnClose
              style={{ width: "100%", margin: "1rem 0" }}
              themeColor={theme}
              color={"#FFFFFF"}
              borderColor={"#B3B9C6"}
              backColor={"#E0271A"}
              type="primary"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </SEC.BtnClose>
          </SEC.InnerSectionSuccess>
        }
      />
    </>
  );
};

// const App = () => {
//   let navigate = useNavigate();
//   let location = useLocation();
//   const dispatch = useDispatch();
//   const access_token = useSelector((state) => state?.access_token);
//   const state = useSelector((state) => state?.userData);
//   const [timeSt, setTimeSt] = useState(
//     new URLSearchParams(location.search).get("timestamp")
//   );
//   const [userId, setUserId] = useState(
//     new URLSearchParams(location.search).get("userId")
//   );
//   const [otpCode, setOtpCode] = useState(
//     new URLSearchParams(location.search).get("code")
//   );
//   const [screenJump, setScreenJump] = useState(
//     new URLSearchParams(location.search).get("type")
//   );
//   const [accessTokens, setAccessTokens] = useState(
//     new URLSearchParams(location.search).get("accessToken")
//   );
//   const [takeSurvey, setTakeSurvey] = useState(
//     new URLSearchParams(location.search).get("take_survey")
//   );
//   const [surveyId, setSurveyId] = useState(
//     new URLSearchParams(location.search).get("surveyId")
//   );

//   const jumpFun = () => {
//     localStorage.clear();
//     dispatch(setAccessToken(""));
//     console.log("Step 1");
//     console.log(accessTokens, "accessTokens");

//     const decodedScreenJump = screenJump?.replace(/['"]+/g, ""); // Remove extra quotes
//     console.log("Decoded screenJump:", decodedScreenJump);

//     if (decodedScreenJump === "resetPassword") {
//       console.log("reset Password");
//       updateAccessToken(accessTokens);
//       // localStorage.setItem("rainLearnerToken", accessTokens);
//       navigate("/client-admin/reset-password", {
//         state: {
//           userId: userId,
//         },
//       });
//     } else if (takeSurvey && surveyId) {
//       console.log("Suvey");
//       navigate("/client-admin/client-survey-form", {
//         state: {
//           surveyId: surveyId,
//         },
//       });
//     }

//     console.log("Step 4");
//   };

//   console.log(access_token, "access_token access_token");
//   return (
//     <CS.ParentBox>
//       {window.location.pathname?.includes("redirect") ? (
//         jumpFun()
//       ) : state ? (
//         <PrivateRoute stateData={state} accessToken={access_token} />
//       ) : (
//         <PublicRoute />
//       )}
//     </CS.ParentBox>
//     // <CS.ParentBox><PrivateRoute /></CS.ParentBox>
//   );
// };

const App = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();
  const access_token = useSelector((state) => state?.access_token);
  const state = useSelector((state) => state?.userData);
  const selectLangJson = useSelector((state) => state?.selectLangJson);

  const [timeSt, setTimeSt] = useState(
    new URLSearchParams(location.search).get("timestamp")
  );
  const [userId, setUserId] = useState(
    new URLSearchParams(location.search).get("userId")
  );
  const [otpCode, setOtpCode] = useState(
    new URLSearchParams(location.search).get("code")
  );
  const [screenJump, setScreenJump] = useState(
    new URLSearchParams(location.search).get("type")
  );
  const [accessTokens, setAccessTokens] = useState(
    new URLSearchParams(location.search).get("accessToken")
  );
  const [takeSurvey, setTakeSurvey] = useState(
    new URLSearchParams(location.search).get("take_survey")
  );
  const [surveyId, setSurveyId] = useState(
    new URLSearchParams(location.search).get("surveyId")
  );
  const isOnBoardingSet = useSelector((state) => state?.isOnBoardingSet);
  const [isOpen, setIsOpen] = useState(false);
  const [isOnBoarding, setIsOnBoarding] = useState(isOnBoardingSet);
  console.log(isOnBoarding, "isOnBoarding ");
  const [onBoardingScreens, setOnBoardingScreens] = useState({
    firstScreen: true,
    secondScreen: false,
    thirdScreen: false,
    fourthScreen: false,
    fifthScreen: false,
    sixthScreen: false,
  });
  const jumpFun = () => {
    localStorage.clear();
    dispatch(setAccessToken(""));
    console.log("Step 1");

    const decodedScreenJump = screenJump?.replace(/['"]+/g, ""); // Remove extra quotes
    console.log("Decoded screenJump:", decodedScreenJump);

    if (decodedScreenJump === "resetPassword") {
      console.log("reset Password");
      updateAccessToken(accessTokens);
      navigate("/client-admin/reset-password", {
        state: {
          userId: userId,
        },
      });
    } else if (takeSurvey && surveyId) {
      console.log("Survey");
      updateAccessToken(accessTokens);
      navigate("/client-admin/client-survey-form", {
        state: {
          surveyId: surveyId,
        },
      });
    }

    console.log("Step 4");
  };

  useEffect(() => {
    console.log("it is running");

    // Trigger jumpFun if the URL contains "/redirect"
    if (window.location.pathname?.includes("redirect")) {
      localStorage.clear();
      jumpFun();

      dispatch(setAccessToken(""));
      dispatch(setProfileData(""));
      dispatch(setClientId(""));
      console.log("it is running");
    }
  }, [
    screenJump, // Dependencies
    takeSurvey,
    surveyId,
    accessTokens,
    userId,
    navigate,
    dispatch,
  ]);

  console.log(access_token, "old access_token");
  console.log(accessTokens, "New access_token");

  console.log("it is running step 2");

  useEffect(() => {
    if (window.location.pathname?.includes("redirect")) {
      localStorage.clear();
      jumpFun();
      dispatch(setAccessToken(""));
      dispatch(setProfileData(""));
      dispatch(setClientId(""));
    }
  }, [dispatch, window.location.pathname]);

  const handleScreenClick = (screen) => {
    setOnBoardingScreens({
      firstScreen: false,
      secondScreen: false,
      thirdScreen: false,
      fourthScreen: false,
      fifthScreen: false,
      sixthScreen: false,
      sevenScreen: false,
      eightScreen: false,
      [screen]: true,
    });
  };

  const resetScreens = () => {
    setOnBoardingScreens({
      firstScreen: false,
      secondScreen: false,
      thirdScreen: false,
      fourthScreen: false,
      fifthScreen: false,
      sixthScreen: false,
      sevenScreen: false,
      eightScreen: false,
    });
    setIsOnBoarding(false);
    dispatch(setIsOnBoardingSet(false));
  };

  useEffect(() => {
    if (isOnBoardingSet) {
      setIsOnBoarding(true);
      setOnBoardingScreens({
        firstScreen: true,
        secondScreen: false,
        thirdScreen: false,
        fourthScreen: false,
        fifthScreen: false,
        sixthScreen: false,
        sevenScreen: false,
        eightScreen: false,
      });
    }
  }, [isOnBoardingSet]);
  return (
    <>
      <CS.ParentBox>
        {window.location.pathname?.includes(
          "redirect"
        ) ? null : access_token ? (
          <PrivateRoute stateData={state} accessToken={access_token} />
        ) : (
          <PublicRoute />
        )}
      </CS.ParentBox>
      {/* OnBoarding */}
      <ModalPo
        maxWidth={"700px"}
        isOpen={isOnBoarding}
        backColor={colorProvider.darkBlue}
        component={
          <AS.ArrContainer>
            {onBoardingScreens?.firstScreen && (
              <AS.ArrInnerContainer>
                <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst>
                {/* <AS.ArrFirst>
                    <img src={demoImage2} alt="" />
                  </AS.ArrFirst> */}
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sevenScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("eightScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.welcome_to_workflix}
                      </div>
                      <div className="desc">{selectLangJson?.welcome_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("secondScreen")}
                      >
                        {selectLangJson?.get_started}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.secondScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage2} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sevenScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("eightScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.course_onboad_head}
                      </div>
                      <div className="desc">{selectLangJson?.course_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("thirdScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.thirdScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage4} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sevenScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("eightScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.support_onboard_head}
                      </div>
                      <div className="desc">{selectLangJson?.support_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("fourthScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.fourthScreen && (
              <AS.ArrInnerContainer>
                {/* <AS.ArrFirst>
                  <img src={demoImage1} alt="" />
                </AS.ArrFirst> */}
                <AS.ArrFirst>
                  <img src={demoImage8} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sevenScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("eightScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.language_onboard_head}
                      </div>
                      <div className="desc">{selectLangJson?.language_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("fifthScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.fifthScreen && (
              <AS.ArrInnerContainer>
                <AS.ArrFirst>
                  <img src={demoImage7} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sevenScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("eightScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">{selectLangJson?.dark_head}</div>
                      <div className="desc">{selectLangJson?.dark_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("sixthScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}

            {onBoardingScreens?.sixthScreen && (
              <AS.ArrInnerContainer>
                <AS.ArrFirst>
                  <img src={demoImage5} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sevenScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("eightScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">
                        {selectLangJson?.profile_head}
                      </div>
                      <div className="desc">{selectLangJson?.profile_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("sevenScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}
            {onBoardingScreens?.sevenScreen && (
              <AS.ArrInnerContainer>
                <AS.ArrFirst>
                  <img src={demoImage6} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sevenScreen")}
                      isActive={true}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("eightScreen")}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">{selectLangJson?.lead_head}</div>
                      <div className="desc">{selectLangJson?.lead_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div
                        className="orangebox"
                        onClick={() => handleScreenClick("eightScreen")}
                      >
                        {selectLangJson?.next}
                      </div>
                      <div className="skip-text" onClick={resetScreens}>
                        {selectLangJson?.skip}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}
            {onBoardingScreens?.eightScreen && (
              <AS.ArrInnerContainer>
                <AS.ArrFirst>
                  <img src={demoImage3} alt="" />
                </AS.ArrFirst>
                <AS.ArrSecond>
                  <AS.DotRow>
                    <AS.DotCom
                      onClick={() => handleScreenClick("firstScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("secondScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("thirdScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fourthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("fifthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sixthScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("sevenScreen")}
                    ></AS.DotCom>
                    <AS.DotCom
                      onClick={() => handleScreenClick("eightScreen")}
                      isActive={true}
                    ></AS.DotCom>
                  </AS.DotRow>
                  <AS.ContentBox>
                    <AS.FirstContent>
                      <div className="title">{selectLangJson?.report_head}</div>
                      <div className="desc">{selectLangJson?.report_msg}</div>
                    </AS.FirstContent>
                    <AS.SecondContent>
                      <div className="orangebox" onClick={resetScreens}>
                        {selectLangJson?.close}
                      </div>
                    </AS.SecondContent>
                  </AS.ContentBox>
                </AS.ArrSecond>
              </AS.ArrInnerContainer>
            )}
          </AS.ArrContainer>
        }
      />
    </>
  );
};
export default App;
