import React, { useState, useEffect } from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import * as LS from "../../../styles/LearnerStyled";
import * as CDD from "../../../styles/CourseDetStyled";
import * as CDS from "../../../styles/CourseDetailStyled";
import * as SEC from "../../../styles/SectionStyled";
import * as UE from "../../../styles/UserElement";
import editIcon from "../../../assets/images/editLight.png";
import lighRight from "../../../assets/images/lighRight.png";
import lightLeft from "../../../assets/images/lightLeft.png";
import darkArrow from "../../../assets/images/darkArrow.png";
import darkRight from "../../../assets/images/darkRight.png";
import dotImage from "../../../assets/images/dotImage.png";
import * as CS from "../../../styles/CommonStyled";
import Modal from "../../../components/Modal/Modal";
import successModal from "../../../assets/images/successModal.png";
import { Table } from "antd";
import {
  clientValidator,
  clientCourseValidator,
  learnerClientValidator,
  policyClientValidator,
  ClientBillingValidator,
  URLValidator,
} from "../../../utils/validator";
import darkCheckboxBaseIcon from "../../../assets/images/dark_base.png";
import darkcheckIcon from "../../../assets/images/darkcheckIcon.png";
import checkboxIcon from "../../../assets/images/Checkbox.png";
import checkboxBaseIcon from "../../../assets/images/_Checkbox_base.png";
import Input from "../../../components/Inputs/InputAdd";
import PageSelect from "../../../components/PageSelect";
import Switch from "../../../components/Switch/Switch";
import plsuIcon from "../../../assets/images/plsuIcon.png";
import SampleExcel from "../../../assets/images/Learners.xlsx";
import downloadIcon from "../../../assets/images/downloadIcon.png";
import UploadTrailer from "../../../components/UploadTrailer";
import darkUploadIcon from "../../../assets/images/darkUploadIcon.png";
import upIcon from "../../../assets/images/upIcon.png";
import {
  combineDateAndTime,
  countryValidationData,
  formatFileSize,
} from "../../../utils/Helpers";
import csvIcon from "../../../assets/images/csvIcon.png";
import delIcon from "../../../assets/images/delete.png";
import orangeDownloadIcon from "../../../assets/images/orangeDownloadIcon.png";
import CourseView from "./CourseView";
import { Course } from "../../../utils/api";
import axios from "../../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { setErrorPop, setErrorPopMsg } from "../../../store/action";
import InputMobile from "../../../components/InputMobile";
import { PopUp } from "../../../components/PopUp/PopUp";

const AddLearner = ({ theme, navigate, localState, dispatch }) => {
  const [showsucessMail, setShowsucessMail] = useState(false);
  // const [learnerInitialValues, setLearnerInitialValues] = useState({
  //   data: [
  //     {
  //       name: "",
  //       email: "",
  //       mobileNumber: "",
  //       mobileNumberCountryCode: "+91",
  //       department: "",
  //       reportingManager: "",
  //       city: "",
  //       designation: "",
  //       subEntity: "",
  //       activate: false,
  //     },
  //   ],
  //   isAdd: true,
  //   isUpload: false,
  //   isTable: false,
  //   isTableSingle: false,
  //   fileName: "",
  //   fileSize: "",
  //   fileUrl: "",
  // });
  const [learnerInitialValues, setLearnerInitialValues] = useState({
    data: [
      {
        name: "",
        email: "",
        mobileNumber: "",
        mobileNumberCountryCode: "",
        department: "",
        reportingManager: "",
        city: "",
        designation: "",
        subEntity: "",
        activate: false,
      },
    ],
    table_data: [
      {
        name: "",
        email: "",
        mobileNumber: "",
        mobileNumberCountryCode: "",
        department: "",
        reportingManager: "",
        city: "",
        designation: "",
        subEntity: "",
        activate: false,
      },
    ],
    backup_data: [
      {
        name: "",
        email: "",
        mobileNumber: "",
        mobileNumberCountryCode: "",
        department: "",
        reportingManager: "",
        city: "",
        designation: "",
        subEntity: "",
        activate: false,
      },
    ],
    singlename: "",
    singleemail: "",
    singlemobileNumber: "",
    singlemobileNumberCountryCode: "",
    singledepartment: "",
    singlereportingManager: "",
    singlecity: "",
    singledesignation: "",
    singlesubEntity: "",
    singleactivate: false,
    singleIndex: null,
    isAdd: true,
    isUpload: false,
    isTable: false,
    isTableSingle: false,
    fileName: "",
    fileSize: "",
    fileUrl: "",
  });

  const courseId = useSelector((state) => state?.courseId);
  const clientId = useSelector((state) => state?.clientId);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCountLearner, setTotalCountLearner] = useState(0);

  const pageLimit = 10;
  const [isLoadingUpload, setIsLoadingUpload] = useState(false);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [isLoading, setIsLoading] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const [totalCount, setTotalCount] = useState(0);

  const handleExcel = async (e) => {
    let url =
      "https://vc.rainmaker.co.in/api/v2/admin/private/clientLearner/excel/upload/";
    const formData = new FormData();
    formData.append("excelFile", e);
    // console.log(e);
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    try {
      const { data } = await axios.post(url, formData, config);
      // setIsLoadingUpload(false);
      return data;
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // const handleLearner = async (values) => {
  //   console.log(values, "values");
  //   let url = Course.learnerAdd;
  //   setIsLoading(true);
  //   var formData = {
  //     data: values?.data?.map((val, ind) => {
  //       return {
  //         userName: val.name,
  //         email: val.email,
  //         mobileNumber: val.mobileNumber?.toString(),
  //         mobileNumberCountryCode: val.mobileNumberCountryCode,
  //         department: val.department,
  //         reportingManager: val.reportingManager,
  //         city: val.city,
  //         designation: val.designation,
  //         subEntity: val.subEntity,
  //         active: val.activate ? 1 : 0,
  //         clientId: clientId,
  //         clientCourseId: courseId,
  //       };
  //     }),
  //   };
  //   try {
  //     const { data } = await axios.post(url, formData);
  //     setIsLoading(false);
  //     navigate(-1);
  //   } catch (error) {
  //     let errObj = {};
  //     if (error.response && error.response.status === 400) {
  //       dispatch(setErrorPop(true));
  //       dispatch(setErrorPopMsg(error?.response?.data?.message));
  //     }
  //     error.response.data.errors?.map((val) => {
  //       if (!errObj[val.path]) {
  //         errObj[val.path] = val.msg;
  //       }
  //     });
  //     setErrorObj(errObj);
  //     setIsLoading(false);
  //   }
  // };

  const handleLearner = async (values) => {
    setIsLoading(true);
    console.log(values, "values");
    let url = Course.learnerAdd;
    var formData = {
      data: values?.data?.map((val) => {
        const obj = {
          userName: val.name?.trim(""), // Assuming name is always present
          active: val.activate ? 1 : 0,
          clientId: clientId,
          clientCourseId: courseId,
        };

        // Conditionally add properties if they are not empty or undefined
        if (val.email) obj.email = val.email?.trim("");
        if (val.mobileNumber) obj.mobileNumber = val.mobileNumber.toString();
        if (val.mobileNumberCountryCode)
          obj.mobileNumberCountryCode = val.mobileNumberCountryCode;
        if (val.department) obj.department = val.department;
        if (val.reportingManager) obj.reportingManager = val.reportingManager;
        if (val.city) obj.city = val.city;
        if (val.designation) obj.designation = val.designation;
        if (val.subEntity) obj.subEntity = val.subEntity;

        return obj;
      }),
    };
    var formDataBulk = {
      data: values?.table_data?.map((val) => {
        const obj = {
          userName: val.name?.trim(""), // Assuming name is always present
          active: val.activate ? 1 : 0,
          clientId: clientId,
          clientCourseId: courseId,
        };

        // Conditionally add properties if they are not empty or undefined
        if (val.email) obj.email = val.email?.trim("");
        if (val.mobileNumber) obj.mobileNumber = val.mobileNumber.toString();
        if (val.mobileNumberCountryCode)
          obj.mobileNumberCountryCode = val.mobileNumberCountryCode;
        if (val.department) obj.department = val.department;
        if (val.reportingManager) obj.reportingManager = val.reportingManager;
        if (val.city) obj.city = val.city;
        if (val.designation) obj.designation = val.designation;
        if (val.subEntity) obj.subEntity = val.subEntity;

        return obj;
      }),
    };
    try {
      const { data } = await axios.post(
        url,
        values?.isTable ? formDataBulk : formData
      );
      console.log(data);
      setIsLoading(false);
      setShowsucessMail(true);
      setTimeout(() => {
        setShowsucessMail(false);
        navigate("/client-admin/courses-manage/manage-learners");
      }, 2000);
    } catch (error) {
      setIsLoading(false);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      let errObj = {};
      error.response.data.errors?.map((val) => {
        console.log(val.path, "error val");
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
    }
  };
  const pageLimitLearner = 10;
  const handleClose = () => {
    setShowsucessMail(false);
  };
  const numberOfPages = Math.ceil(totalCountLearner / pageLimitLearner);
  return (
    <CDS.DetailContainer>
      {/* <CourseView theme={theme} localState={localState} /> */}
      <CDS.DetailCon>
        <CDS.DetailInnerCon>
          <LS.LearnerBox>
            <Formik
              enableReinitialize
              initialValues={learnerInitialValues}
              validate={learnerClientValidator}
              validateOnChange
              onSubmit={(values) => handleLearner(values, false)}
            >
              {(formikBag) => {
                const handleDelete = (indexToDelete) => {
                  const updatedData = formikBag.values.table_data?.filter(
                    (_, index) => index !== indexToDelete
                  );
                  formikBag.setFieldValue("table_data", updatedData);
                };
                const bulkLearnerColumn = [
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                    ellipsis: true,
                    width: 130,
                    fixed: "left", // Fixes this column to the left
                    render: (text, record) => (
                      <CS.TitleColumn>
                        <CS.ColumnTitle
                          style={{
                            whiteSpace: "nowrap",
                            width: "130px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          themeColor={theme}
                        >
                          {record.name}
                        </CS.ColumnTitle>
                      </CS.TitleColumn>
                    ),
                  },
                  {
                    title: "Email",
                    dataIndex: "email",
                    key: "email",
                    ellipsis: true,
                    width: 130,
                    render: (text, record) => (
                      <CS.TitleColumn>
                        <CS.ColumnEmail
                          style={{
                            whiteSpace: "nowrap",
                            width: "130px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          themeColor={theme}
                        >
                          {record.email}
                        </CS.ColumnEmail>
                      </CS.TitleColumn>
                    ),
                  },
                  {
                    title: "Mobile Number",
                    dataIndex: "mobileNumber",
                    key: "mobileNumber",
                    ellipsis: true,
                    width: 130,
                    render: (text, record) => (
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          width: "130px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {record?.mobileNumberCountryCode} {record?.mobileNumber}
                      </div>
                    ),
                  },
                  {
                    title: "Department",
                    dataIndex: "department",
                    key: "department",
                    ellipsis: true,
                    width: 130,
                    render: (text, record) => (
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          width: "130px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {record?.department}
                      </div>
                    ),
                  },
                  {
                    title: "Reporting Manager",
                    dataIndex: "reportingManager",
                    key: "reportingManager",
                    ellipsis: true,
                    width: 130,
                    render: (text, record) => (
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          width: "130px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {record?.reportingManager}
                      </div>
                    ),
                  },
                  {
                    title: "City",
                    dataIndex: "city",
                    key: "city",
                    ellipsis: true,
                    width: 130,
                    render: (text, record) => (
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          width: "130px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {record?.city}
                      </div>
                    ),
                  },
                  {
                    title: "Designation",
                    dataIndex: "designation",
                    key: "designation",
                    ellipsis: true,
                    width: 130,
                    render: (text, record) => (
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          width: "130px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {record?.designation}
                      </div>
                    ),
                  },
                  {
                    title: "Sub-Entity",
                    dataIndex: "subEntity",
                    key: "subEntity",
                    ellipsis: true,
                    width: 130,
                    render: (text, record) => (
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          width: "130px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {record?.subEntity}
                      </div>
                    ),
                  },
                  {
                    title: "Activate",
                    dataIndex: "active",
                    key: "active",
                    ellipsis: true,
                    width: 130,
                    render: (text, record) => (
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          width: "130px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Switch
                          rounded={true}
                          isToggled={record.activate}
                          bgColorChecked="#F6591C"
                          roundColorChecked="#ffffff"
                          border={
                            theme
                              ? "1px solid transparent"
                              : "1px solid #D7DAE0"
                          }
                          bgColorUnChecked={theme ? "#1F242F" : "#F5F5F6"}
                          themeColor={theme}
                          roundColorUnChecked="#ffffff"
                        />
                      </div>
                    ),
                  },
                  {
                    title: "",
                    dataIndex: "isActive",
                    key: "isActive",
                    ellipsis: true,
                    render: (text, record, index) => (
                      <div style={{ display: "flex" }}>
                        <CS.ComButton
                          isCursor={true}
                          onClick={() => handleDelete(index)}
                        >
                          <img src={delIcon} alt="" />
                        </CS.ComButton>
                        <CS.ComButton
                          isCursor={true}
                          onClick={() => {
                            formikBag.setFieldValue(
                              "singlename",
                              record?.name || ""
                            );
                            formikBag.setFieldValue(
                              "singleemail",
                              record?.email || ""
                            );
                            formikBag.setFieldValue(
                              "singlemobileNumber",
                              record?.mobileNumber || ""
                            );
                            formikBag.setFieldValue(
                              "singlemobileNumberCountryCode",
                              record?.mobileNumberCountryCode || ""
                            );
                            formikBag.setFieldValue(
                              "singledepartment",
                              record?.department || ""
                            );
                            formikBag.setFieldValue(
                              "singlereportingManager",
                              record?.reportingManager || ""
                            );
                            formikBag.setFieldValue(
                              "singlecity",
                              record?.city || ""
                            );
                            formikBag.setFieldValue(
                              "singledesignation",
                              record?.designation || ""
                            );
                            formikBag.setFieldValue(
                              "singlesubEntity",
                              record?.subEntity || ""
                            );
                            formikBag.setFieldValue(
                              "singleactivate",
                              record?.activate || ""
                            );
                            formikBag.setFieldValue("singleIndex", index || 0);
                            formikBag.setFieldValue("isTableSingle", true);
                          }}
                        >
                          <img src={editIcon} alt="" />
                        </CS.ComButton>
                      </div>
                    ),
                  },
                ];
                return (
                  <Form style={{ width: "100%" }}>
                    <LS.FormContainer style={{ padding: 0 }} themeColor={theme}>
                      <LS.FormTitle
                        style={{ marginTop: "0" }}
                        themeColor={theme}
                      >
                        {selectLangJson.add_learner}
                      </LS.FormTitle>
                      <LS.FormContainerBorder
                        themeColor={theme}
                        style={{
                          padding: "0.8rem 0.7rem 0.8rem 1rem",
                          marginTop: "1rem",
                          marginBottom: "1rem",
                        }}
                      >
                        <LS.InputBoxContainers
                          style={{
                            justifyContent: "flex-start",
                          }}
                        >
                          <LS.ComFeedbackRowss>
                            <LS.CheckBoxs
                              onClick={() => {
                                formikBag.setFieldValue(`isAdd`, true);
                                formikBag.setFieldValue(`isUpload`, false);
                              }}
                            >
                              <img
                                src={
                                  formikBag.values.isAdd
                                    ? theme
                                      ? darkcheckIcon
                                      : checkboxIcon
                                    : theme
                                    ? darkCheckboxBaseIcon
                                    : checkboxBaseIcon
                                }
                                alt=""
                              />
                            </LS.CheckBoxs>
                            <LS.FeedbackOrangeTextUpl themeColor={theme}>
                              {selectLangJson.add_manually}
                            </LS.FeedbackOrangeTextUpl>
                          </LS.ComFeedbackRowss>
                          <LS.ComFeedbackRowss>
                            <LS.CheckBoxs
                              onClick={() => {
                                formikBag.setFieldValue(`isUpload`, true);
                                formikBag.setFieldValue(`isAdd`, false);
                              }}
                            >
                              <img
                                src={
                                  formikBag.values.isUpload
                                    ? theme
                                      ? darkcheckIcon
                                      : checkboxIcon
                                    : theme
                                    ? darkCheckboxBaseIcon
                                    : checkboxBaseIcon
                                }
                                alt=""
                              />
                            </LS.CheckBoxs>
                            <LS.FeedbackOrangeTextUpl themeColor={theme}>
                              {selectLangJson.upload}
                            </LS.FeedbackOrangeTextUpl>
                          </LS.ComFeedbackRowss>
                        </LS.InputBoxContainers>
                      </LS.FormContainerBorder>

                      {formikBag.values.isAdd ? (
                        <FieldArray name="data">
                          {({
                            insert,
                            remove: learnerRemove,
                            push: learnerPush,
                          }) => {
                            return (
                              <LS.FormContainer
                                themeColor={theme}
                                style={{ padding: "0", margin: 0 }}
                              >
                                {formikBag.values?.data?.length > 0 &&
                                  formikBag.values?.data?.map((val, ind) => {
                                    return (
                                      <>
                                        <LS.FormContainer
                                          themeColor={theme}
                                          style={{ padding: "0" }}
                                        >
                                          <LS.InputBoxContainer>
                                            <LS.InputBoxs wiDth={"49%"}>
                                              <Field name={`data[${ind}].name`}>
                                                {({ field, form, meta }) => (
                                                  <Input
                                                    {...field}
                                                    theme={theme}
                                                    placeholder={
                                                      selectLangJson.learner_name
                                                    }
                                                    label={
                                                      selectLangJson.learner_name
                                                    }
                                                    star={true}
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? meta.error
                                                        : null
                                                    }
                                                    erroros={
                                                      errorObj[
                                                        `data[${ind}].userName`
                                                      ]
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </LS.InputBoxs>
                                            <LS.InputBoxs wiDth={"49%"}>
                                              <Field
                                                name={`data[${ind}].email`}
                                              >
                                                {({ field, form, meta }) => (
                                                  <Input
                                                    {...field}
                                                    theme={theme}
                                                    placeholder={
                                                      selectLangJson.learner_email
                                                    }
                                                    label={
                                                      selectLangJson.learner_email
                                                    }
                                                    star={true}
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? meta.error
                                                        : null
                                                    }
                                                    erroros={
                                                      errorObj[
                                                        `data[${ind}].email`
                                                      ]
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </LS.InputBoxs>
                                          </LS.InputBoxContainer>

                                          <LS.InputBoxContainer>
                                            <LS.InputBoxs wiDth={"49%"}>
                                              <Field
                                                name={`data[${ind}].mobileNumber`}
                                              >
                                                {({ field, form, meta }) => (
                                                  <InputMobile
                                                    {...field}
                                                    placeholder="Mobile Number"
                                                    label="Mobile Number"
                                                    type="number"
                                                    value={
                                                      val.mobileNumberCountryCode +
                                                      val.mobileNumber
                                                    }
                                                    onChange={(phone, data) => {
                                                      formikBag.setFieldValue(
                                                        `data[${ind}].mobileNumberCountryCode`,
                                                        data.format.slice(
                                                          0,
                                                          1
                                                        ) + data.dialCode
                                                      );
                                                      formikBag.setFieldValue(
                                                        `data[${ind}].mobileNumber`,
                                                        phone.slice(
                                                          data.dialCode.length
                                                        )
                                                      );
                                                    }}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? meta.error
                                                        : null
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </LS.InputBoxs>
                                            <LS.InputBoxs wiDth={"49%"}>
                                              <Field
                                                name={`data[${ind}].department`}
                                              >
                                                {({ field, form, meta }) => (
                                                  <Input
                                                    {...field}
                                                    theme={theme}
                                                    placeholder={
                                                      selectLangJson.department
                                                    }
                                                    label={
                                                      selectLangJson.department
                                                    }
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? meta.error
                                                        : null
                                                    }
                                                    erroros={
                                                      errorObj[
                                                        `data[${ind}].department`
                                                      ]
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </LS.InputBoxs>
                                          </LS.InputBoxContainer>
                                          <LS.InputBoxContainer>
                                            <LS.InputBoxs wiDth={"49%"}>
                                              <Field
                                                name={`data[${ind}].reportingManager`}
                                              >
                                                {({ field, form, meta }) => (
                                                  <Input
                                                    {...field}
                                                    theme={theme}
                                                    placeholder={
                                                      selectLangJson.reporting_manager
                                                    }
                                                    label={
                                                      selectLangJson.reporting_manager
                                                    }
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? meta.error
                                                        : null
                                                    }
                                                    erroros={
                                                      errorObj[
                                                        `data[${ind}].reportingManager`
                                                      ]
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </LS.InputBoxs>
                                            <LS.InputBoxs wiDth={"49%"}>
                                              <Field name={`data[${ind}].city`}>
                                                {({ field, form, meta }) => (
                                                  <Input
                                                    {...field}
                                                    theme={theme}
                                                    placeholder={
                                                      selectLangJson.enter_city
                                                    }
                                                    label={
                                                      selectLangJson.enter_city
                                                    }
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? meta.error
                                                        : null
                                                    }
                                                    erroros={
                                                      errorObj[
                                                        `data[${ind}].city`
                                                      ]
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </LS.InputBoxs>
                                          </LS.InputBoxContainer>
                                          <LS.InputBoxContainer>
                                            <LS.InputBoxs wiDth={"49%"}>
                                              <Field
                                                name={`data[${ind}].designation`}
                                              >
                                                {({ field, form, meta }) => (
                                                  <Input
                                                    {...field}
                                                    theme={theme}
                                                    placeholder={
                                                      selectLangJson.designation
                                                    }
                                                    label={
                                                      selectLangJson.designation
                                                    }
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? meta.error
                                                        : null
                                                    }
                                                    erroros={
                                                      errorObj[
                                                        `data[${ind}].designation`
                                                      ]
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </LS.InputBoxs>
                                            <LS.InputBoxs wiDth={"49%"}>
                                              <Field
                                                name={`data[${ind}].subEntity`}
                                              >
                                                {({ field, form, meta }) => (
                                                  <Input
                                                    {...field}
                                                    theme={theme}
                                                    placeholder={
                                                      selectLangJson.sub_entity
                                                    }
                                                    label="Sub Entity"
                                                    onChange={form.handleChange}
                                                    onBlur={form.handleBlur}
                                                    error={
                                                      meta.touched && meta.error
                                                        ? meta.error
                                                        : null
                                                    }
                                                  />
                                                )}
                                              </Field>
                                            </LS.InputBoxs>
                                          </LS.InputBoxContainer>

                                          <LS.DefaultBox>
                                            <LS.DefaultText themeColor={theme}>
                                              {selectLangJson.active_inactive} :
                                            </LS.DefaultText>
                                            <LS.DefaultToggle
                                              style={{ marginLeft: "10px" }}
                                            >
                                              <Switch
                                                rounded={true}
                                                isToggled={val.activate}
                                                bgColorChecked="#F6591C"
                                                roundColorChecked="#ffffff"
                                                border={
                                                  theme
                                                    ? "1px solid transparent"
                                                    : "1px solid #D7DAE0"
                                                }
                                                bgColorUnChecked={
                                                  theme ? "#1F242F" : "#F5F5F6"
                                                }
                                                roundColorUnChecked="#ffffff"
                                                onToggle={() => {
                                                  formikBag.setFieldValue(
                                                    `data[${ind}].activate`,
                                                    !val.activate
                                                  );
                                                  // setIsToggled(!isToggled)
                                                }}
                                              />
                                            </LS.DefaultToggle>
                                          </LS.DefaultBox>
                                        </LS.FormContainer>
                                        {formikBag.values?.data?.length > 1 ? (
                                          <LS.OrangeButtonText
                                            style={{
                                              justifyContent: "right",
                                              margin: 0,
                                            }}
                                            onClick={() => learnerRemove(ind)}
                                          >
                                            {selectLangJson.delete}
                                          </LS.OrangeButtonText>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    );
                                  })}
                                <LS.OrangeButtonText
                                  onClick={() => {
                                    learnerPush({
                                      name: "",
                                      email: "",
                                      mobileNumber: "",
                                      mobileNumberCountryCode: "+91",
                                      department: "",
                                      reportingManager: "",
                                      city: "",
                                      designation: "",
                                      subEntity: "",
                                      activate: false,
                                    });
                                  }}
                                >
                                  <img
                                    src={plsuIcon}
                                    style={{ marginRight: "10px" }}
                                    alt=""
                                  />
                                  {selectLangJson.add_new}
                                </LS.OrangeButtonText>
                              </LS.FormContainer>
                            );
                          }}
                        </FieldArray>
                      ) : (
                        ""
                      )}
                      {formikBag.values.isUpload ? (
                        <>
                          <LS.FormContainer
                            style={{ padding: 0 }}
                            themeColor={theme}
                          >
                            <LS.RowTitle>
                              <LS.InputLabless themeColor={theme}>
                                {selectLangJson.upload_learners}
                                <span style={{ color: "#F6591C" }}>*</span>
                              </LS.InputLabless>
                              <LS.OrangeTemplate href={SampleExcel} download>
                                <img src={orangeDownloadIcon} alt="" />{" "}
                                {selectLangJson.download_template}
                              </LS.OrangeTemplate>
                            </LS.RowTitle>
                            <UploadTrailer
                              type="file"
                              id="pic"
                              theme={theme}
                              // label="Upload Translations"
                              upIcon={theme ? darkUploadIcon : upIcon}
                              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              accepTed="text/csv"
                              other="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              others="application/vnd.ms-excel"
                              alertMsg={selectLangJson.no_csv_found}
                              description={selectLangJson?.xls_desc}
                              // star={true}
                              onChange={async (e) => {
                                const file = e.target.files[0];

                                // Function to check if the file is CSV or Excel
                                const isCsvOrExcel = (file) => {
                                  return (
                                    file?.type === "text/csv" ||
                                    file?.type ===
                                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                                    file?.type === "application/vnd.ms-excel"
                                  );
                                };

                                if (!isCsvOrExcel(file)) {
                                  alert(selectLangJson?.no_csv_fount);
                                } else {
                                  setIsLoadingUpload(true);
                                  formikBag.setFieldValue(
                                    `fileName`,
                                    file?.name
                                  );
                                  formikBag.setFieldValue(
                                    `fileSize`,
                                    formatFileSize(file?.size)
                                  );
                                  var imageObj = await handleExcel(file);
                                  console.log(
                                    imageObj,
                                    "imageObj imageObj imageObj"
                                  );
                                  if (imageObj && imageObj.result) {
                                    const exceObj = imageObj?.result?.map(
                                      (val, ind) => {
                                        let emailVal;
                                        if(typeof val.email === 'object'){
                                          emailVal = val?.email?.text
                                        }
                                        else {
                                          emailVal = val?.email
                                        } 
                                        return {
                                          name: val.name || "",
                                          email: emailVal || "",
                                          mobileNumber:
                                            val.mobile?.toString() || "",
                                          mobileNumberCountryCode: "+91",
                                          department: val.department || "",
                                          reportingManager:
                                            val.reportingManager || "",
                                          city: val.city || "",
                                          designation: val.designation || "",
                                          subEntity: val.subEntity || "",
                                          activate:
                                            val.active === 1 ? true : false,
                                        };
                                      }
                                    );
                                    console.log(exceObj, "exceObj  exceObj");

                                    e.target.value = "";
                                    setIsLoadingUpload(false);
                                    formikBag.setFieldValue(
                                      "table_data",
                                      exceObj
                                    );
                                    formikBag.setFieldValue(
                                      "backup_data",
                                      exceObj
                                    );
                                  }
                                }
                              }}
                              error={
                                formikBag.touched.fileUrl &&
                                formikBag.errors.fileUrl
                                  ? formikBag.errors.fileUrl
                                  : null
                              }
                            />
                            {formikBag.values.fileName ? (
                              <LS.ButtonRow themeColor={theme} isBorder={true}>
                                <LS.ButtonGroups themeColor={theme}>
                                  <CDD.CancelButton
                                    themeColor={theme}
                                    style={{
                                      margin: "0 0.8rem 0 0",
                                    }}
                                    onClick={() => {
                                      formikBag.setFieldValue(`isAdd`, true);
                                      formikBag.setFieldValue(
                                        `isUpload`,
                                        false
                                      );
                                      formikBag.setFieldValue(`isTable`, false);
                                      formikBag.setFieldValue("data", []);
                                    }}
                                    type="button"
                                  >
                                    {selectLangJson.cancel}
                                  </CDD.CancelButton>
                                  <CDD.SaveButton
                                    style={{
                                      margin: 0,
                                      fontWeight: "600",
                                    }}
                                    className="buttonload"
                                    type="button"
                                    onClick={async () => {
                                      formikBag.setFieldValue("isAdd", false);
                                      formikBag.setFieldValue("isUpload", true);
                                      formikBag.setFieldValue("isTable", true);
                                      formikBag.setFieldValue(
                                        "isUpload",
                                        false
                                      );
                                    }}
                                  >
                                    {selectLangJson.upload}
                                  </CDD.SaveButton>
                                </LS.ButtonGroups>
                              </LS.ButtonRow>
                            ) : (
                              ""
                            )}
                            {formikBag.values.fileName ? (
                              <LS.FileRow
                                themeColor={theme}
                                style={{
                                  marginTop: "1rem",
                                  justifyContent: "space-between",
                                }}
                              >
                                <LS.FileBox
                                  themeColor={theme}
                                  style={{ width: "97%" }}
                                >
                                  <img src={csvIcon} alt="" />
                                  <LS.FileContentBox themeColor={theme}>
                                    <div className="head">
                                      {formikBag.values.fileName}
                                    </div>
                                    <div className="desc">
                                      {formikBag.values.fileSize} - 100%{" "}
                                      {selectLangJson.uploaded}
                                    </div>
                                  </LS.FileContentBox>
                                </LS.FileBox>
                                {/* <LS.DelIconTwo
                                themeColor={theme}
                                onClick={() => {
                                  
                                }}
                              >
                                <img src={downloadIcon} alt="" />
                              </LS.DelIconTwo> */}
                                <LS.DelIconTwo
                                  themeColor={theme}
                                  onClick={() => {
                                    formikBag.setFieldValue(`fileUrl`, "");
                                    formikBag.setFieldValue(`fileName`, "");
                                    formikBag.setFieldValue(`fileSize`, "");
                                    const fileInput =
                                      document.getElementById("pic");
                                    if (fileInput) fileInput.value = "";
                                  }}
                                >
                                  <img src={delIcon} alt="" />
                                </LS.DelIconTwo>
                              </LS.FileRow>
                            ) : (
                              ""
                            )}
                          </LS.FormContainer>
                        </>
                      ) : (
                        ""
                      )}
                      {formikBag.values.isTable ? (
                        <SEC.ParentTable>
                          <SEC.TableBoxLearner themeColor={theme}>
                            <Table
                              rowKey={(record) => record._id}
                              style={{
                                width: "100%",
                                // minHeight: "500px",
                              }}
                              rowSelection={false}
                              columns={bulkLearnerColumn}
                              className={
                                theme
                                  ? "dark-table border-border"
                                  : "light-table border-border"
                              }
                              dataSource={formikBag.values.table_data}
                              scroll={{ x: true, y: true }}
                              pagination={{
                                current: currentPage,
                                pageSize: 10,
                                total: totalCount,
                                onChange: (page) => {
                                  setCurrentPage(page);
                                },
                                // You can use this property to customize the rendered pagination component
                                itemRender: (
                                  current,
                                  type,
                                  originalElement
                                ) => {
                                  const isDisabled = currentPage === 1;
                                  if (type === "prev") {
                                    return (
                                      <SEC.PagiButton
                                        themeColor={theme}
                                        disabled={isDisabled}
                                        onClick={() => {
                                          !isDisabled &&
                                            setCurrentPage(currentPage - 1);
                                        }}
                                      >
                                        <img
                                          src={theme ? lightLeft : darkArrow}
                                          style={{ marginRight: "8px" }}
                                          alt=""
                                        />{" "}
                                        <p>Previous</p>
                                      </SEC.PagiButton>
                                    );
                                  }
                                  if (type === "next") {
                                    const isDisabled =
                                      currentPage === numberOfPages;
                                    return (
                                      <SEC.PagiButtonNext
                                        disabled={isDisabled}
                                        themeColor={theme}
                                        onClick={() => {
                                          !isDisabled &&
                                            setCurrentPage(currentPage + 1);
                                        }}
                                      >
                                        <p>Next</p>
                                        <img
                                          src={theme ? lighRight : darkRight}
                                          style={{ marginLeft: "8px" }}
                                          alt=""
                                        />
                                      </SEC.PagiButtonNext>
                                    );
                                  }
                                  return originalElement;
                                },
                              }}
                            />
                          </SEC.TableBoxLearner>
                        </SEC.ParentTable>
                      ) : (
                        ""
                      )}
                      <Modal
                        isOpen={formikBag.values.isTableSingle}
                        onClose={() => {
                          formikBag.setFieldValue("isTableSingle", false);
                        }}
                        maxWidth="700px"
                        component={
                          <SEC.InnerSectionsCommit themeColor={theme}>
                            <UE.FormContainer
                              style={{
                                padding: 0,
                                marginTop: "1.5rem",
                              }}
                              themeColor={theme}
                            >
                              <UE.FormContainer
                                themeColor={theme}
                                style={{ padding: "0" }}
                              >
                                <UE.InputBoxContainer>
                                  <UE.InputBox wiDth={"49%"}>
                                    <Field name={`singlename`}>
                                      {({ field, form, meta }) => (
                                        <Input
                                          {...field}
                                          placeholder="Learner Name"
                                          label="Name"
                                          star={true}
                                          theme={theme}
                                          onChange={(e) => {
                                            const newValue = e.target.value;

                                            formikBag.setFieldValue(
                                              "singlename",
                                              newValue
                                            );
                                          }}
                                          error={
                                            formikBag.touched.singlename &&
                                            formikBag.errors.singlename
                                              ? formikBag.errors.singlename
                                              : null
                                          }
                                          disabled
                                        />
                                      )}
                                    </Field>
                                  </UE.InputBox>
                                  <UE.InputBox wiDth={"49%"}>
                                    <Field name={`singleemail`}>
                                      {({ field, form, meta }) => (
                                        <Input
                                          {...field}
                                          placeholder="Learner Email"
                                          label="Email"
                                          theme={theme}
                                          star={true}
                                          onChange={(e) => {
                                            const newValue = e.target.value;

                                            formikBag.setFieldValue(
                                              "singleemail",
                                              newValue
                                            );
                                          }}
                                          error={
                                            formikBag.touched.singleemail &&
                                            formikBag.errors.singleemail
                                              ? formikBag.errors.singleemail
                                              : null
                                          }
                                        />
                                      )}
                                    </Field>
                                  </UE.InputBox>
                                </UE.InputBoxContainer>

                                <UE.InputBoxContainer>
                                  <UE.InputBox wiDth={"49%"}>
                                    <Field name={`singlemobileNumber`}>
                                      {({ field, form, meta }) => (
                                        <InputMobile
                                          {...field}
                                          placeholder="Mobile Number"
                                          label="Mobile Number"
                                          icon={true}
                                          type="number"
                                          theme={theme}
                                          value={
                                            formikBag.values
                                              .singlemobileNumberCountryCode +
                                              formikBag.values
                                                .singlemobileNumber || ""
                                          }
                                          onChange={(phone, data) => {
                                            formikBag.setFieldValue(
                                              `singlemobileNumberCountryCode`,
                                              data.format.slice(0, 1) +
                                                data.dialCode
                                            );
                                            formikBag.setFieldValue(
                                              `singlemobileNumber`,
                                              phone.slice(data.dialCode.length)
                                            );
                                          }}
                                          error={
                                            formikBag.touched.singleemail &&
                                            formikBag.errors.singleemail
                                              ? formikBag.errors.singleemail
                                              : null
                                          }
                                        />
                                      )}
                                    </Field>
                                  </UE.InputBox>
                                  <UE.InputBox wiDth={"49%"}>
                                    <Field name="singledepartment">
                                      {({ field }) => (
                                        <Input
                                          {...field}
                                          placeholder="Department"
                                          label="Department"
                                          theme={theme}
                                          value={
                                            formikBag.values.singledepartment
                                          }
                                          onChange={(e) => {
                                            formikBag.setFieldValue(
                                              "singledepartment",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </UE.InputBox>
                                </UE.InputBoxContainer>
                                <UE.InputBoxContainer>
                                  <UE.InputBox wiDth={"49%"}>
                                    <Field name={`singlereportingManager`}>
                                      {({ field, form, meta }) => (
                                        <Input
                                          {...field}
                                          disabled
                                          placeholder="Reporting Manager"
                                          label="Reporting Manager"
                                          theme={theme}
                                          onChange={(e) => {
                                            formikBag.setFieldValue(
                                              "singlereportingManager",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </UE.InputBox>
                                  <UE.InputBox wiDth={"49%"}>
                                    <Field name={`singlecity`}>
                                      {({ field, form, meta }) => (
                                        <Input
                                          {...field}
                                          disabled
                                          placeholder="Enter City"
                                          label="City"
                                          theme={theme}
                                          onChange={(e) => {
                                            formikBag.setFieldValue(
                                              "singlecity",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </UE.InputBox>
                                </UE.InputBoxContainer>
                                <UE.InputBoxContainer>
                                  <UE.InputBox wiDth={"49%"}>
                                    <Field name={`singledesignation`}>
                                      {({ field, form, meta }) => (
                                        <Input
                                          {...field}
                                          placeholder="Designation"
                                          label="Designation"
                                          theme={theme}
                                          value={
                                            formikBag.values.singledesignation
                                          }
                                          onChange={(e) => {
                                            formikBag.setFieldValue(
                                              "singledesignation",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </UE.InputBox>
                                  <UE.InputBox wiDth={"49%"}>
                                    <Field name={`singlesubEntity`}>
                                      {({ field, form, meta }) => (
                                        <Input
                                          {...field}
                                          disabled
                                          theme={theme}
                                          placeholder="Sub Entity"
                                          label="Sub Entity"
                                          onChange={(e) => {
                                            formikBag.setFieldValue(
                                              "singlesubEntity",
                                              e.target.value
                                            );
                                          }}
                                        />
                                      )}
                                    </Field>
                                  </UE.InputBox>
                                </UE.InputBoxContainer>

                                <UE.DefaultBox>
                                  <UE.DefaultText themeColor={theme}>
                                    Activate :
                                  </UE.DefaultText>
                                  <UE.DefaultToggle>
                                    <Switch
                                      rounded={true}
                                      isToggled={
                                        formikBag.values.singleactivate
                                      }
                                      bgColorChecked="#F6591C"
                                      roundColorChecked="#ffffff"
                                      border={
                                        theme
                                          ? "1px solid transparent"
                                          : "1px solid #D7DAE0"
                                      }
                                      themeColor={theme}
                                      bgColorUnChecked={
                                        theme ? "#1F242F" : "#F5F5F6"
                                      }
                                      roundColorUnChecked="#ffffff"
                                      onToggle={() => {
                                        formikBag.setFieldValue(
                                          `singleactivate`,
                                          !formikBag.values.singleactivate
                                        );
                                        // setIsToggled(!isToggled)
                                      }}
                                    />
                                  </UE.DefaultToggle>
                                </UE.DefaultBox>
                              </UE.FormContainer>
                              <UE.ButtonGroup themeColor={theme}>
                                <UE.CancelButton
                                  themeColor={theme}
                                  onClick={() => {
                                    formikBag.setFieldValue(
                                      "isTableSingle",
                                      false
                                    );
                                  }}
                                >
                                  Cancel
                                </UE.CancelButton>
                                <UE.SaveButton
                                  //  onClick={() => {
                                  //   formikBag.setFieldValue("isTableSingle", false);
                                  // }}
                                  onClick={() => {
                                    // Manual validation
                                    const errors = [];
                                    if (!formikBag.values.singlename) {
                                      errors.push("Learner Name is required.");
                                    }

                                    if (!formikBag.values.singleemail) {
                                      errors.push("Learner Email is required.");
                                    } else {
                                      const emailRegex =
                                        /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                      if (
                                        !emailRegex.test(
                                          formikBag.values.singleemail
                                        )
                                      ) {
                                        errors.push(
                                          "Learner Email is invalid."
                                        );
                                      }
                                    }
                                    if (formikBag.values.singlemobileNumber) {
                                      if (
                                        formikBag.values
                                          .singlemobileNumberCountryCode &&
                                        formikBag.values.singlemobileNumber
                                      ) {
                                        // Find country data based on mobileCountryCode
                                        const countryData =
                                          countryValidationData?.find(
                                            (country) =>
                                              country.dialCode ===
                                              formikBag.values
                                                .singlemobileNumberCountryCode
                                          );

                                        if (countryData) {
                                          // Validate the length of the mobile number
                                          if (
                                            formikBag.values.singlemobileNumber
                                              ?.length !== countryData?.length
                                          ) {
                                            errors.push(
                                              `Invalid Mobile Number. It should be ${countryData.length} digits for ${countryData.country}.`
                                            );
                                          }
                                        }
                                      }
                                    }

                                    if (errors.length > 0) {
                                      alert(errors.join("\n")); // Show errors in an alert
                                    } else {
                                      // Proceed with saving the data
                                      formikBag.setFieldValue(
                                        "isTableSingle",
                                        false
                                      );
                                      formikBag.setFieldValue(
                                        `table_data.${formikBag.values.singleIndex}.name`,
                                        formikBag.values.singlename
                                      );
                                      formikBag.setFieldValue(
                                        `table_data.${formikBag.values.singleIndex}.email`,
                                        formikBag.values.singleemail
                                      );
                                      formikBag.setFieldValue(
                                        `table_data.${formikBag.values.singleIndex}.mobileNumber`,
                                        formikBag.values.singlemobileNumber
                                      );
                                      formikBag.setFieldValue(
                                        `table_data.${formikBag.values.singleIndex}.department`,
                                        formikBag.values.singledepartment
                                      );
                                      formikBag.setFieldValue(
                                        `table_data.${formikBag.values.singleIndex}.reportingManager`,
                                        formikBag.values.singlereportingManager
                                      );
                                      formikBag.setFieldValue(
                                        `table_data.${formikBag.values.singleIndex}.city`,
                                        formikBag.values.singlecity
                                      );
                                      formikBag.setFieldValue(
                                        `table_data.${formikBag.values.singleIndex}.activate`,
                                        formikBag.values.singleactivate
                                      );
                                      // Add save logic here...
                                    }
                                  }}
                                  type="button"
                                >
                                  Save
                                </UE.SaveButton>
                              </UE.ButtonGroup>
                            </UE.FormContainer>
                          </SEC.InnerSectionsCommit>
                        }
                      />
                      <LS.ButtonGroup style={{ margin: 0 }} themeColor={theme}>
                        <CDD.CancelButton
                          type="button"
                          themeColor={theme}
                          onClick={() => {
                            navigate("/client-admin/courses-manage/manage-learners");
                          }}
                        >
                          {selectLangJson.cancel}
                        </CDD.CancelButton>
                        <CDD.SaveButton
                          cursor={isLoading}
                          disabled={isLoading}
                          className="buttonload"
                          type="submit"
                        >
                          {isLoading ? (
                            <i class="fa fa-spinner fa-spin"></i>
                          ) : (
                            selectLangJson.save
                          )}
                        </CDD.SaveButton>
                      </LS.ButtonGroup>
                    </LS.FormContainer>
                  </Form>
                );
              }}
            </Formik>
          </LS.LearnerBox>
        </CDS.DetailInnerCon>
      </CDS.DetailCon>

      <PopUp
        isOpen={showsucessMail}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>
              {selectLangJson?.great}!
            </SEC.TitleTwo>

            <SEC.Subtitle style={{ textAlign: "center" }} themeColor={theme}>
              {selectLangJson?.your_data_has_been_successfully_saved}.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucessMail(false);
                navigate("/client-admin/courses-manage/manage-learners");
              }}
            >
              {selectLangJson?.continue}
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
    </CDS.DetailContainer>
  );
};

export default AddLearner;
