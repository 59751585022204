import React, { useState, useEffect } from "react";
import * as CDS from "../../../styles/CourseDetailStyled";
import * as CDD from "../../../styles/CourseDetStyled";
import * as RNS from "../../../styles/RenewStyled";
import * as LS from "../../../styles/LearnerStyled";
import mediaImage from "../../../assets/images/mediaImage.png";
import downArrow from "../../../assets/images/downArrow.png";
import editDark from "../../../assets/images/editDark.png";
import YearInput from "../../../components/DatePicker";
import CourseView from "./CourseView";
import * as RS from "../../../styles/ReqStyled";
import Modal from "../../../components/Modal/Modal";
import { Formik, Field, Form, FieldArray } from "formik";
import Input from "../../../components/Inputs/InputAdd";
import axios from "../../../utils/axios";
import { Course } from "../../../utils/api";
import { useSelector } from "react-redux";
import { get } from "lodash";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  setCourseViewId,
  setErrorPop,
  setErrorPopMsg,
} from "../../../store/action";
import * as SEC from "../../../styles/SectionStyled";
import { PopUp } from "../../../components/PopUp/PopUp";
import successModal from "../../../assets/images/successModal.png";
import { useLocation } from "react-router-dom";
import { encryptData } from "../../../utils/Helpers";
import { batchValidator } from "../../../utils/validator";

const Renew = ({ navigate, theme, dispatch, localState }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(location?.state?.popUp || false);
  const courseId = useSelector((state) => state?.courseId);
  const [stateData, setStateData] = useState(location?.state?.popUp);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  console.log(stateData, "stateData stateDatastateData");
  const [courseDetailData, setCourseDetailData] = useState([]);
  const [feildLoading, setFeildLoading] = useState(false);
  const selectLang = useSelector((state) => state.selectLang);
  const [addiValue, setAddiValue] = useState({});
  const [showsucess, setShowsucess] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  const getCourseContent = async () => {
    setFeildLoading(true);
    let url = `${Course.getCourseContent}details`;
    const formValue = {
      _id: courseId,
      languageId: selectLang,
    };
    try {
      const { data } = await axios.post(url, formValue);
      setFeildLoading(false);
      setCourseDetailData(data.data[0]);
    } catch (error) {
      // alert(error.response.data.message);
      setFeildLoading(false);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    getCourseContent();
  }, [courseId, selectLang]);
  const handleClose = (e) => {
    setIsOpen(e);
  };
  const handleSubmit = async (values) => {
    setAddiValue(values);
    setIsOpen(false);
  };
  const handleSubmitRenew = async (values) => {
    console.log(values);
    try {
      let url = Course.numberLicencePurchases;
      let formValue = {
        ...values,
      };
      const { data } = await axios.post(url, formValue);
      setShowsucess(true);
      setIsOpen(false);
      getCourseContent()
      setTimeout(() => {
        setShowsucess(false);
      }, 2000);
      
    } catch (error) {
      console.log(error.response.data.message);
      let errObj = {};
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      error.response.data.errors?.map((val) => {
        console.log(val.path, "error val");
        if (!errObj[val.path]) {
          errObj[val.path] = val.msg;
        }
      });
      setErrorObj(errObj);
    }
  };

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";

  const getCourseContents = async () => {
    setFeildLoading(true);
    let url = `${Course.getCourseContent}content`;
    const formValue = {
      _id: courseId,
      languageId: selectLang,
    };
    try {
      const { data } = await axios.post(url, formValue);
      // setCourseContentData(data.data[0]);
      dispatch(setCourseViewId(data.data[0]));
      localStorage.setItem("rainCourseViewId", encryptData(data.data[0]));
      // localStorage.setItem("rainCourseViewId", JSON.stringify(data.data[0]));
      setFeildLoading(false);
    } catch (error) {
      setFeildLoading(false);
      // alert(error.response.data.message);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    getCourseContents();
  }, [courseId, selectLang]);
  return (
    <>
      <CDS.DetailContainer>
        {/* <CourseView
          theme={theme}
          courseId={courseId}
          navigate={navigate}
          localState={localState}
        /> */}
        <CDS.DetailCon themeColor={theme}>
          <CDS.DetailInnerCon>
            {feildLoading ? (
              <Skeleton
                width={200}
                baseColor={skeletonBaseColor}
                highlightColor={skeletonHighlightColor}
                height={28}
                style={{
                  borderRadius: "5px",
                  margin: "1rem 0",
                }}
              />
            ) : (
              <RS.ReqTitle themeColor={theme}>
                {selectLangJson.renew_licenses}
              </RS.ReqTitle>
            )}

            <CDD.DetContainer>
              {feildLoading ? (
                <div>
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                </div>
              ) : (
                <CDD.DetailCom themeColor={theme}>
                  <p className="name">{selectLangJson.category}</p>
                  <p className="name">{selectLangJson.course}</p>
                  <p className="name">
                    {selectLangJson.subscription_start_date}
                  </p>
                  <p className="name">{selectLangJson.subscription_end_date}</p>
                  <p className="name">
                    {selectLangJson.number_of_licenses_purchased}
                  </p>
                  <p className="name">
                    {selectLangJson.cost_per_individual_license}
                  </p>
                  <p className="name">
                    {selectLangJson.content_languages_opted}
                  </p>
                  <p className="name">{selectLangJson.batch_purchase}</p>
                  <p className="name">{selectLangJson.batch_size}</p>
                  <p className="name">
                    {selectLangJson.cost_per_license_for_batch}
                  </p>
                </CDD.DetailCom>
              )}
              {feildLoading ? (
                <div>
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                  <Skeleton
                    width={200}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{
                      borderRadius: "5px",
                      margin: "0.3rem 2rem 0.3rem 0",
                    }}
                  />
                </div>
              ) : (
                <CDD.DetailCom themeColor={theme}>
                  <p className="valo">
                    {get(courseDetailData, "categorie", "-")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "courseName", "-")}
                  </p>

                  <p className="valo">
                    {moment(get(courseDetailData, "startDate", "-")).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                  <p className="valo">
                    {moment(get(courseDetailData, "endDate", "-")).format(
                      "DD/MM/YYYY"
                    )}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "numberOfLicensesPurchased", "-")}
                    <img
                      src={editDark}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                      alt=""
                    />
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "costPerLicences", "-")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "languages", [])
                      ?.map((val) => val.title)
                      ?.join(", ")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "batchPurchase", "-") === 1
                      ? selectLangJson.yes
                      : selectLangJson.no}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "batchSize", "-")}
                  </p>
                  <p className="valo">
                    {get(courseDetailData, "cost_per_license2", "-")}
                  </p>
                </CDD.DetailCom>
              )}

              {/* <CDD.DetailCom>
                <YearInput
                  placeholderText="15/05/2024"
                  label="End Date"
                />
              </CDD.DetailCom> */}
            </CDD.DetContainer>
            <CDD.ButtonGroup themeColor={theme}>
              <CDD.CancelButton
                themeColor={theme}
                type="button"
                onClick={() => {
                  navigate(
                    "/client-admin/courses-manage/request-additional-learners"
                  );
                }}
              >
                {selectLangJson.cancel}
              </CDD.CancelButton>
              <CDD.SaveButton
                cursor={isLoading || !addiValue?.value}
                disabled={isLoading || !addiValue?.value}
                style={{ opacity: !addiValue?.value ? "0.3" : "1" }}
                className="buttonload"
                onClick={() => { 
                  handleSubmitRenew(addiValue);
                }}
              >
                {isLoading ? (
                  <i class="fa fa-spinner fa-spin"></i>
                ) : (
                  selectLangJson.submit
                )}
              </CDD.SaveButton>
            </CDD.ButtonGroup>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
        <Modal
          maxWidth={"600px"}
          isOpen={isOpen}
          theme={theme}
          minHeight={"200px"}
          component={
            <RNS.RenewBox themeColor={theme}>
              <Formik
                enableReinitialize
                initialValues={{ value: "", courseId: courseId }}
                validateOnChange
                validate={batchValidator}
                onSubmit={(values) => handleSubmit(values, false)}
              >
                {(formikBag) => (
                  <Form style={{ width: "100%" }}>
                    <LS.InputBoxs style={{ width: "100%" }}>
                      <Field name="value">
                        {({ field, form, meta }) => (
                          <Input
                            {...field}
                            theme={theme}
                            type="number"
                            star={true}
                            placeholder={
                              selectLangJson.number_of_licenses_purchased
                            }
                            label={selectLangJson.number_of_licenses_purchased}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            error={
                              meta.touched && meta.error ? meta.error : null
                            }
                            errors={errorObj?.value}
                          />
                        )}
                      </Field>
                    </LS.InputBoxs>
                    <CDD.ButtonGroup
                      style={{ marginTop: 0 }}
                      themeColor={theme}
                    >
                      <CDD.CancelButton
                        themeColor={theme}
                        onClick={() => {
                          setIsOpen(false);
                        }}
                      >
                        {selectLangJson.cancel}
                      </CDD.CancelButton>
                      <CDD.SaveButton
                        cursor={isLoading}
                        disabled={isLoading}
                        className="buttonload"
                        type="submit"
                      >
                        {isLoading ? (
                          <i class="fa fa-spinner fa-spin"></i>
                        ) : (
                          selectLangJson.save_changes
                        )}
                      </CDD.SaveButton>
                    </CDD.ButtonGroup>
                  </Form>
                )}
              </Formik>
            </RNS.RenewBox>
          }
        />
      </CDS.DetailContainer>
      <PopUp
        isOpen={showsucess}
        onClose={handleClose}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxTwo>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxTwo>
          </>
        }
        content={
          <SEC.InnerSection>
            <SEC.TitleTwo themeColor={theme}>
              {selectLangJson.great}!
            </SEC.TitleTwo>

            <SEC.Subtitle themeColor={theme} style={{ textAlign: "center" }}>
              {selectLangJson.your_data_has_been_successfully_saved}.
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                setShowsucess(false);
              }}
            >
              {selectLangJson.continue}
            </SEC.GreenContinue>
          </SEC.InnerSection>
        }
      />
    </>
  );
};

export default Renew;
