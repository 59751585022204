import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as HS from "../../styles/HeaderStyled";
import logoImage from "../../assets/images/rainLightSvg.svg";
import rainLightLogo from "../../assets/images/rainLightSvg.svg";
import downIcon from "../../assets/images/downIcon.png";
import searchIcon from "../../assets/images/searchIcon.png";
import * as LS from "../../styles/LearnerStyled";
import langIcon from "../../assets/images/langIcon.png";
import bellIcon from "../../assets/images/bellIcon.png";
import sunIcon from "../../assets/images/sunIcon.png";
import hills from "../../assets/images/hills.png";
import Avatar from "../../assets/images/Avatar.png";
import lightIcon from "../../assets/images/light.png";
import darkIcon from "../../assets/images/dark.png";
import {
  setIsDark,
  setSidebarIsOpens,
  setUserData,
  setSelectLang,
  setSelectLangJson,
  setProfileData,
  setProfileDetail,
  setIsOnBoardingSet,
  setErrorPop,
  setErrorPopMsg,
} from "../../store/action";
import darkLogo from "../../assets/images/rainDarkSvg.svg";
import darkSearch from "../../assets/images/darkSearch.png";
import darkLang from "../../assets/images/darkLang.png";
import darkBell from "../../assets/images/darkBell.png";
import darkDonwArrow from "../../assets/images/darkDonwArrow.png";
import drawer from "../../assets/images/drawer.png";
import darkDrawer from "../../assets/images/darkDrawer.png";
import AvatarIcon from "../../assets/images/Avatar.png";
import mainLogo from "../../assets/images/rainLogo.png";
import crossBig from "../../assets/images/crossBig.png";
import learnerSwitch from "../../assets/images/learnerSwitch.png";
import accountIcon from "../../assets/images/accountIcon.png";
import logoutIconGrey from "../../assets/images/logoutIconGrey.png";
import infoIcon from "../../assets/images/infoIcon.png";
import switchIcon from "../../assets/images/switch-user.png";
import warns from "../../assets/images/warns.png";
import darkMoon from "../../assets/images/darkMoon.png";
import Switch from "../../components/Switch/Switch";
import { get } from "lodash";
import SelectQues from "../../components/SelectQues";
import englishLang from "../../utils/Language/english.json";
import hindiLang from "../../utils/Language/hindi.json";
import kannadaLang from "../../utils/Language/kannada.json";
import { Course, ProfileInfo } from "../../utils/api";
import axios from "../../utils/axios";
import profileDemo from "../../assets/images/profileDemo.png";
import squareImage from "../../assets/images/squareImage.png";
import moment from "moment/moment";
import { encryptData } from "../../utils/Helpers";
import CryptoJS from "crypto-js";
import { jwtDecode } from "jwt-decode";
import { PopUp } from "../../components/PopUp/PopUp";
import * as SEC from "../../styles/SectionStyled";
import warnIcon from "../../assets/images/warn.png";
import { ModalSuccess } from "../../components/Modal/ModalSuccess";
import successModal from "../../assets/images/successModal.png";

const secretKeyMain = "rmAdminSecretKey";

const quesOptions = [
  { value: "single_choice", label: "Single Choice" },
  { value: "descriptive", label: "Descriptive" },
  { value: "multiple_choice", label: "Multiple Choice" },
];
const Navbar = ({ dispatch, theme, navigate, stateData, localState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [navbarData, setNavbarData] = useState(
    get(stateData, "data.client_info")
  );
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const languages = useSelector((state) => state?.languages);
  const [languageData, setLanguageData] = useState(
    get(stateData, "data.client_info.language", [])?.map((val) => {
      return {
        label: val.title,
        value: val._id,
        isDefault: val.isDefault,
      };
    })
  );

  const profileUpdate = useSelector((state) => state?.profileUpdate);
  const profileData = useSelector((state) => state?.profileData);
  const profileDetail = useSelector((state) => state.profileDetail);
  const selectLang = useSelector((state) => state?.selectLang);
  const access_token = useSelector((state) => state?.access_token);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const decoded = jwtDecode(access_token);

  const { userId } = decoded;
  console.log(decoded, "decoded decoded decoded");
  const [defaultLang, setDefaultLang] = useState(selectLang);
  useEffect(() => {
    if (languageData?.length > 0) {
      let selectedLang = languageData?.find((val) => val._id === defaultLang);
      setDefaultLang(selectedLang);
    }
  }, [languageData]);

  console.log(selectLang, "selectLang selectLang");
  console.log(defaultLang, "defaultLang defaultLang");

  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [isRedBox, setIsRedBox] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [loginValues, setLoginValues] = useState(profileData);
  const [opens, setOpens] = useState(false);
  useEffect(() => {
    // Initialize theme from local storage if available
    const savedTheme = localStorage.getItem("rainTheme");
    if (savedTheme !== null) {
      dispatch(setIsDark(savedTheme === "true")); // Convert the string to a boolean
    }
  }, [dispatch]);
  // console.log(languageData, "languageData");
  const screenWidth = window.innerWidth;
  const profileRef = useRef(null); // Create a ref for the profile menu
  const supportRef = useRef(null); // Create a ref for the profile menu
  const notificationRef = useRef(null); // Create a ref for the profile menu
  const handleThemeChange = () => {
    const newTheme = !theme;
    localStorage.setItem("rainTheme", newTheme); // Save theme to local storage
    dispatch(setIsDark(newTheme));
  };
  const [innerwidth, setInnerWidth] = useState(window.innerWidth);
  const [langOptions, setLangOptions] = useState(
    languages?.map((val) => {
      return {
        label: val.title,
        value: val._id,
      };
    })
  );
  const handleChangeLanguage = (selectedOption) => {
    setSelectedLanguage(selectedOption.value);
    dispatch(setSelectLang(selectedOption.value)); // Assuming this action updates the language
    setDefaultLang(selectedOption.value);
    const encryptedSelectLang = CryptoJS.AES.encrypt(
      selectedOption.value,
      secretKeyMain
    ).toString();
    window.localStorage.setItem("rainClientLang", encryptedSelectLang);
  };

  const handleConfirmChangeLanguage = () => {
    // Execute the language change logic
    // Close the modal
  };
  useEffect(() => {
    function handleResize() {
      setInnerWidth(window.innerWidth);
      // console.log("Screen size changed. New width: ", window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleSidebarChange = () => {
    dispatch(setSidebarIsOpens(true));
  };
  const pathName = [
    "/client-admin/courses",
    "/client-admin/courses-manage/courses-content",
    "/client-admin/courses-manage/courses-detail",
    "/client-admin/courses-manage/manage-learners",
    "/client-admin/courses-manage/learner-view",
    "/client-admin/courses-manage/add-learner",
    "/client-admin/courses-manage/policy-document",
    "/client-admin/courses-manage/add-policy",
    "/client-admin/courses-manage/request-additional-learners",
    "/client-admin/courses-manage/renew",
    "/client-admin/courses-manage/committee",
    "/client-admin/courses-manage/add-committee",
  ];
  const reportPathName = [
    "/client-admin/reports/master-reports",
    "/client-admin/reports/course-reports",
    "/client-admin/reports/client-reports",
    "/client-admin/reports/agreement-reports",
    "/client-admin/reports/industry-reports",
    "/client-admin/reports/roll-out-reports",
    "/client-admin/reports/progress-reports",
    "/client-admin/reports/master-shedule",
    "/client-admin/reports/master-add-shedule",
    "/client-admin/reports/entry-reports",
    "/client-admin/reports/entry-shedule",
    "/client-admin/reports/entry-add-shedule",
    "/client-admin/reports/entry-reports",
    "/client-admin/reports/course-shedule",
    "/client-admin/reports/course-add-shedule",
    "/client-admin/reports/client-shedule",
    "/client-admin/reports/client-add-shedule",
    "/client-admin/reports/agreement-shedule",
    "/client-admin/reports/agreement-add-shedule",
    "/client-admin/reports/industry-shedule",
    "/client-admin/reports/industry-add-shedule",
    "/client-admin/reports/roll-out-shedule",
    "/client-admin/reports/roll-out-add-shedule",
    "/client-admin/reports/progress-add-shedule",
    "/client-admin/reports/audit-shedule",
    "/client-admin/reports/audit-add-shedule",
    "/client-admin/reports/feedback-reports",
    "/client-admin/reports/feedback-shedule",
    "/client-admin/reports/feedback-add-shedule",
    "/client-admin/reports/add-delete-learner-reports",
    "/client-admin/reports/audit-reports",
    "/client-admin/reports/progress-shedule",
    "/client-admin/reports/audit-reports",
    "/client-admin/reports/add-delete-learner-shedule",
    "/client-admin/reports/add-delete-learner-add-shedule",
    "/client-admin/reports/completion-reports",
    "/client-admin/reports/completion-shedule",
    "/client-admin/reports/completion-add-shedule",
    "/client-admin/reports/department-reports",
    "/client-admin/reports/department-shedule",
    "/client-admin/reports/department-add-shedule",
    "/client-admin/reports/location-reports",
    "/client-admin/reports/location-shedule",
    "/client-admin/reports/location-add-shedule",
    "/client-admin/reports/reporting-reports",
    "/client-admin/reports/reporting-shedule",
    "/client-admin/reports/reporting-add-shedule",
    "/client-admin/reports/year-reports",
    "/client-admin/reports/year-shedule",
    "/client-admin/reports/year-add-shedule",
    "/client-admin/reports/licenses-reports",
    "/client-admin/reports/licenses-shedule",
    "/client-admin/reports/licenses-add-shedule",
    "/client-admin/reports/quiz-analysis-reports",
    "/client-admin/reports/quiz-analysis-shedule",
    "/client-admin/reports/quiz-analysis-add-shedule",
  ];
  const handleConfirm = () => {
    setDeleteSuccess(true);
    setIsOpen(false);
    setOpens(false);
    setTimeout(() => {
      setDeleteSuccess(false);
      localStorage.removeItem("rainDataToken");
      localStorage.removeItem("rainClientId");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("rainClientUserData");
      localStorage.removeItem("rainClientLang");
      localStorage.removeItem("clientLanguagesObj");
      localStorage.removeItem("rainClientData");
      localStorage.removeItem("rainClientToken");
      localStorage.removeItem("rainClientUserData");
      localStorage.removeItem("rainClientUserDetail");
      dispatch(setUserData(""));
      dispatch(setIsDark(true));
      window.location.href = "/client-admin";
    }, 2000);
  };
  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setIsNotificationOpen(false);
      }
      if (supportRef.current && !supportRef.current.contains(event.target)) {
        setIsSupport(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef, supportRef, notificationRef]);

  const getProfileDetails = async () => {
    try {
      let url = ProfileInfo.getProfileDetails;
      const { data } = await axios.get(url);
      if (data && data.data) {
        const { firstName, lastName, email, profileImage } = data.data;
        const userData = {
          firstName: firstName || "",
          lastName: lastName || "",
          email: email || "",
          profileImage: profileImage || "",
        };
        setLoginValues(userData);
        dispatch(setProfileData(userData));
        localStorage.setItem("rainClientUserData", encryptData(userData));
      } else {
        setLoginValues({
          firstName: "",
          lastName: "",
          email: "",
          profileImage: "",
        });
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  const getProfileDetailsTwo = async () => {
    try {
      let url = ProfileInfo.getCompanyDetails;
      const { data } = await axios.get(url);
      // localStorage.setItem(
      //   "rainClientUserDetail",
      //   JSON.stringify(data?.data[0])
      // );
      dispatch(setProfileDetail(data?.data[0]));
      const encryptedRainUserData = CryptoJS.AES.encrypt(
        JSON.stringify(data?.data[0]),
        secretKeyMain
      ).toString();
      localStorage.setItem("rainClientUserDetail", encryptedRainUserData);
      // setDetailData(data?.data[0]);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const getNotification = async () => {
    let url = `${ProfileInfo.getNotification}?page=1&limit=6`;
    try {
      const { data } = await axios.get(url);
      if (data?.result) {
        setNotificationData(data?.result?.docs);
        const hasReadMark = data?.result?.docs?.some((val) => val.readMark);
        setIsRedBox(hasReadMark);
        setIsNotificationOpen(!isNotificationOpen);
      } else {
        setNotificationData([]);

        setIsNotificationOpen(!isNotificationOpen);
      }
    } catch (error) {
      console.log(error.response.data.message);
    } finally {
    }
  };
  useEffect(() => {
    if (
      !profileData ||
      Object.keys(profileData)?.length === 0 ||
      !profileUpdate
    ) {
      getProfileDetails();
    }
  }, [profileData]);
  // useEffect(() => {
  //   if (isNotificationOpen) {
  //     getNotification();
  //   }
  // }, [isNotificationOpen]);

  const handleImpersonate = async () => {
    try {
      let url = `${Course.getImpersonateLink}/${userId}?userType=client_admin`;
      const { data } = await axios.get(url);
      if (data) {
        window.location.href = data;
        setIsOpen(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
      setIsOpen(false);
      console.log(error.response.data.message);
    }
  };

  const handleCloses = () => {
    setOpens(false);
    setDeleteSuccess(false);
  };
  return (
    <>
      <HS.HeaderBox themeColor={theme}>
        {innerwidth > 768 ? (
          <>
            <HS.NavBar>
              <HS.FirstContainer>
                {theme ? (
                  <HS.MainLogoTwo
                    onClick={() => navigate("/client-admin/dashboard")}
                  >
                    <img src={darkLogo} alt="" />
                  </HS.MainLogoTwo>
                ) : (
                  <HS.MainLogoTwo
                    onClick={() => navigate("/client-admin/dashboard")}
                  >
                    <img src={rainLightLogo} alt="" />
                  </HS.MainLogoTwo>
                )}

                <HS.Options
                  themeColor={theme}
                  to="/client-admin"
                  isActive={
                    window.location.pathname === "/client-admin/dashboard"
                  }
                >
                  {selectLangJson.dashboard}
                </HS.Options>
                <HS.Options
                  themeColor={theme}
                  to="/client-admin/courses"
                  isActive={pathName?.includes(window.location.pathname)}
                >
                  {selectLangJson.courses}
                </HS.Options>
                <HS.Options
                  themeColor={theme}
                  to="/client-admin/reports/master-reports"
                  isActive={reportPathName?.includes(window.location.pathname)}
                >
                  {selectLangJson.report}
                </HS.Options>
                <HS.OptionsSupport
                  onClick={() => {
                    setIsSupport(!isSupport);
                  }}
                  themeColor={theme}
                >
                  <HS.ComSupport themeColor={theme}>
                    <span>{selectLangJson.support}</span>
                    <HS.DownLogo
                      src={theme ? darkDonwArrow : downIcon}
                      alt=""
                    />
                  </HS.ComSupport>
                  {isSupport ? (
                    <HS.SupportMenuCon ref={supportRef} themeColor={theme}>
                      <p>{selectLangJson?.raise_a_ticket}</p>
                      <p>{selectLangJson?.chat_with_us}</p>
                      <p
                        onClick={() => {
                          dispatch(setIsOnBoardingSet(true));
                        }}
                      >
                        {selectLangJson?.onBoarding_guide}
                      </p>
                    </HS.SupportMenuCon>
                  ) : (
                    ""
                  )}
                </HS.OptionsSupport>
              </HS.FirstContainer>
              <HS.SecondContainer>
                <HS.ComIconContainers themeColor={theme}>
                  {/* <img src={theme ? darkSearch : searchIcon} alt="" /> */}
                </HS.ComIconContainers>
                {/* <HS.ComIconContainers themeColor={theme}>
                <span themeColor={theme}>English</span>
                <HS.DownLogo src={theme ? darkDonwArrow : downIcon} alt="" />
              </HS.ComIconContainers> */}
                <HS.ComIconContainersDown themeColor={theme}>
                  <img src={theme ? darkLang : langIcon} alt="" />
                  <SelectQues
                    options={langOptions}
                    star={false}
                    value={langOptions?.find(
                      (val) => val.value === defaultLang
                    )}
                    placeholder="Select"
                    onChange={handleChangeLanguage}
                  />
                </HS.ComIconContainersDown>
                <HS.ComIconContainers
                  onClick={() => {
                    getNotification();
                  }}
                  themeColor={theme}
                >
                  <img src={theme ? darkBell : bellIcon} alt="" />
                </HS.ComIconContainers>
                <HS.ComIconContainers
                  themeColor={theme}
                  onClick={handleThemeChange}
                  style={{ borderRight: "0" }}
                >
                  <img src={theme ? darkIcon : lightIcon} alt="" />
                </HS.ComIconContainers>
                <HS.ProfileImage
                  themeColor={theme}
                  onClick={() => {
                    setIsProfileOpen(!isProfileOpen);
                  }}
                  style={{ marginRight: "15px" }}
                >
                  <img src={profileData?.profileImage || profileDemo} alt="" />
                </HS.ProfileImage>
                <HS.ProfileLogoImage style={{ marginRight: "15px" }}>
                  <img
                    src={
                      profileDetail?.client_informations?.logo?.logoImage ||
                      squareImage
                    }
                    alt=""
                  />
                </HS.ProfileLogoImage>

                {isProfileOpen ? (
                  <HS.AccountBox themeColor={theme} ref={profileRef}>
                    <HS.ProfileRow themeColor={theme}>
                      <HS.ProfileBox themeColor={theme}>
                        <img
                          src={profileData?.profileImage || profileDemo}
                          alt=""
                        />
                      </HS.ProfileBox>
                      <HS.NameBox themeColor={theme}>
                        <HS.NameTitle themeColor={theme}>
                          {profileData?.firstName} {profileData?.lastName}
                        </HS.NameTitle>
                        <HS.NameEmail themeColor={theme}>
                          {profileData?.email}
                        </HS.NameEmail>
                      </HS.NameBox>
                    </HS.ProfileRow>
                    {/* <HS.ComMenuBoxNoBorder
                    themeColor={theme}
                    to="/client-admin/profile-details"
                    onClick={() => {
                      setIsProfileOpen(false);
                    }}
                  >
                    <img src={accountIcon} alt="" />
                    {selectLangJson.view_profile}
                  </HS.ComMenuBoxNoBorder> */}

                    <HS.ComMenuBoxCon
                      themeColor={theme}
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img src={learnerSwitch} alt="" />
                        {selectLangJson?.switch_to_learner}
                      </div>
                      <LS.DefaultToggle>
                        <Switch
                          rounded={true}
                          isToggled={isOpen}
                          bgColorChecked="#F6591C"
                          roundColorChecked="#ffffff"
                          border={
                            theme
                              ? "1px solid transparent"
                              : "1px solid #D7DAE0"
                          }
                          themeColor={theme}
                          wiDth={"40px"}
                          bgColorUnChecked={theme ? "#1F242F" : "#F5F5F6"}
                          roundColorUnChecked="#ffffff"
                          onToggle={() => {
                            setIsOpen(true);
                            handleImpersonate();
                          }}
                        />
                      </LS.DefaultToggle>
                    </HS.ComMenuBoxCon>
                    <HS.ComMenuBox
                      themeColor={theme}
                      to="/client-admin/profile-details"
                      onClick={() => {
                        setIsProfileOpen(false);
                        getProfileDetailsTwo();
                      }}
                    >
                      <img src={accountIcon} alt="" />
                      {selectLangJson.account_settings}
                    </HS.ComMenuBox>
                    <HS.ComMenuBox
                      themeColor={theme}
                      onClick={() => {
                        setOpens(true);
                      }}
                      style={{ border: "none" }}
                    >
                      <img src={logoutIconGrey} alt="" />
                      {selectLangJson.log_out}
                    </HS.ComMenuBox>
                  </HS.AccountBox>
                ) : (
                  ""
                )}
                {isNotificationOpen ? (
                  <HS.NotificationBox themeColor={theme} ref={notificationRef}>
                    <HS.NotiTitleRow>
                      <HS.NotiTitle themeColor={theme}>
                        {selectLangJson.notifications}
                      </HS.NotiTitle>
                      <HS.SeeAllText
                        to="/client-admin/notification"
                        onClick={() => {
                          setIsNotificationOpen(false);
                        }}
                      >
                        {selectLangJson.see_all}
                      </HS.SeeAllText>
                    </HS.NotiTitleRow>
                    <HS.NotiContentBox>
                      {notificationData?.length > 0 ? (
                        notificationData?.slice(0, 5)?.map((val, ind) => {
                          // if (val.readMark) {
                          //   setIsRedBox(true);
                          // }
                          return (
                            <HS.NotificationCard key={ind} themeColor={theme}>
                              <HS.NotiLogo>
                                <img src={infoIcon} alt="" />
                              </HS.NotiLogo>
                              <HS.NotiContent>
                                <HS.NotiMessage themeColor={theme}>
                                  {val.body}
                                </HS.NotiMessage>
                                <HS.TimeViewRow>
                                  <HS.TimeBox themeColor={theme}>
                                    {moment(val.createdAt).format("h:mm:ss")}
                                    &nbsp;&nbsp;
                                    {moment(val.createdAt).format(
                                      "DD MMM YYYY"
                                    )}
                                  </HS.TimeBox>
                                  {val.viewStatus ? (
                                    <HS.ViewBox themeColor={theme}>
                                      {selectLangJson?.view}
                                    </HS.ViewBox>
                                  ) : (
                                    ""
                                  )}
                                </HS.TimeViewRow>
                              </HS.NotiContent>
                            </HS.NotificationCard>
                          );
                        })
                      ) : (
                        <HS.NotificationCard
                          themeColor={theme}
                          style={
                            theme
                              ? {
                                  color: "#fff",
                                  display: "flex",
                                  justifyContent: "center",
                                }
                              : {
                                  color: "#000",
                                  display: "flex",
                                  justifyContent: "center",
                                }
                          }
                        >
                          {selectLangJson?.no_data}
                        </HS.NotificationCard>
                      )}
                      {/* <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={infoIcon} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                        <HS.ViewBox>View</HS.ViewBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard>
                  <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={warns} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard>
                  <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={infoIcon} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                        <HS.ViewBox>View</HS.ViewBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard>
                  <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={infoIcon} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                        <HS.ViewBox>View</HS.ViewBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard>
                  <HS.NotificationCard>
                    <HS.NotiLogo>
                      <img src={warns} alt="" />
                    </HS.NotiLogo>
                    <HS.NotiContent>
                      <HS.NotiMessage>
                        You have a new request of additional license from
                        Deepika for the WorkSafe Delta.
                      </HS.NotiMessage>
                      <HS.TimeViewRow>
                        <HS.TimeBox>18-01-2023 10:56:42</HS.TimeBox>
                      </HS.TimeViewRow>
                    </HS.NotiContent>
                  </HS.NotificationCard> */}
                    </HS.NotiContentBox>
                  </HS.NotificationBox>
                ) : (
                  ""
                )}
              </HS.SecondContainer>
            </HS.NavBar>
          </>
        ) : (
          ""
        )}

        {innerwidth <= 768 ? (
          <>
            <HS.NavBar>
              <HS.FirstContainer>
                <HS.MainLogo onClick={() => navigate("/")}>
                  <img src={theme ? darkLogo : logoImage} alt="" />
                </HS.MainLogo>
              </HS.FirstContainer>
              <HS.SecondContainer style={{ width: "auto" }}>
                <HS.ComIconContainers
                  themeColor={theme}
                  onClick={handleThemeChange}
                  style={{ borderRight: "0" }}
                >
                  <img src={theme ? darkIcon : lightIcon} alt="" />
                </HS.ComIconContainers>
                <HS.ComIconContainers
                  themeColor={theme}
                  onClick={handleSidebarChange}
                  style={{ borderRight: "0" }}
                >
                  <img
                    src={theme ? darkDrawer : drawer}
                    alt=""
                    style={{ width: "35px", height: "35px" }}
                  />
                </HS.ComIconContainers>
              </HS.SecondContainer>
            </HS.NavBar>
          </>
        ) : (
          ""
        )}
      </HS.HeaderBox>
      <PopUp
        isOpen={opens}
        onClose={handleCloses}
        maxWidth="600px"
        title={
          <>
            <SEC.WarnIconBox>
              <img src={warnIcon} alt="danger" />
            </SEC.WarnIconBox>
          </>
        }
        content={
          <>
            <SEC.InnerSection themeColor={theme}>
              <SEC.TitleNormal
                style={{ textAlign: "center" }}
                themeColor={theme}
              >
                {selectLangJson.are_you_sure}!
              </SEC.TitleNormal>
              {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or)
              </SEC.TitleDesc> */}
              <SEC.Subtitle style={{ textAlign: "center" }} themeColor={theme}>
                {selectLangJson.are_you_sure_exit}
              </SEC.Subtitle>
              <SEC.BtnGroup>
                <SEC.ComButton
                  themeColor={theme}
                  onClick={() => setOpens(false)}
                  type="button"
                >
                  {selectLangJson.cancel}
                </SEC.ComButton>
                <SEC.ComButton
                  themeColor={theme}
                  color={"#FFFFFF"}
                  borderColor={"#B3B9C6"}
                  backColor={"#E0271A"}
                  onClick={() => {
                    handleConfirm();
                  }}
                >
                  {selectLangJson.yes_logout}
                </SEC.ComButton>
              </SEC.BtnGroup>
            </SEC.InnerSection>
          </>
        }
      />
      <ModalSuccess
        isOpen={deleteSuccess}
        onClose={handleCloses}
        maxWidth="400px"
        title={
          <>
            <SEC.SuccessIconBoxThree>
              <img src={theme ? successModal : successModal} alt="danger" />
            </SEC.SuccessIconBoxThree>
          </>
        }
        content={
          <SEC.InnerSectionSuccessTwo>
            <SEC.TitleSuccess themeColor={theme}>Great!</SEC.TitleSuccess>
            {/* <SEC.TitleDesc themeColor={theme}>
                (Category contains associated content or) 
              </SEC.TitleDesc> */}
            <SEC.Subtitle themeColor={theme}>
              {selectLangJson.success_log_out}
            </SEC.Subtitle>
            <SEC.GreenContinue
              onClick={() => {
                handleCloses();
                setDeleteSuccess(false);
                localStorage.removeItem("rainDataToken");
                localStorage.removeItem("rainClientId");
                localStorage.removeItem("accessToken");
                localStorage.removeItem("rainClientUserData");
                localStorage.removeItem("rainClientLang");
                localStorage.removeItem("clientLanguagesObj");
                localStorage.removeItem("rainClientData");
                localStorage.removeItem("rainClientToken");
                localStorage.removeItem("rainClientUserData");
                localStorage.removeItem("rainClientUserDetail");
                dispatch(setUserData(""));
                dispatch(setIsDark(true));
                window.location.href = "/client-admin";
              }}
            >
              {selectLangJson.ok}
            </SEC.GreenContinue>
          </SEC.InnerSectionSuccessTwo>
        }
      />
    </>
  );
};

export default Navbar;
