import React, { useState, useEffect, useContext, useRef } from "react";
import * as CDS from "../../../styles/CourseDetailStyled";
import mediaImage from "../../../assets/images/mediaImage.png";
import downArrow from "../../../assets/images/downArrow.png";
import upArrow from "../../../assets/images/upArrow.png";
import darkUp from "../../../assets/images/darkUp.png";
import darkDown from "../../../assets/images/darkDown.png";
import playButton from "../../../assets/images/playButton.png";
import CourseView from "./CourseView";
import axios from "../../../utils/axios";
import { Course } from "../../../utils/api";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../utils/socket";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import VideoModal from "../../../components/VideoModal/Modal";
import VideoJS from "../../../components/Video/VideoMedia";
import * as SS from "../../../styles/SectionStyled";
import {
  setCourseViewId,
  setErrorPop,
  setErrorPopMsg,
} from "../../../store/action";
import {
  convertTimeFormatMin,
  encryptData,
  formatTime,
} from "../../../utils/Helpers";
import ReactHlsPlayer from "react-hls-player";
import ModalTwo from "../../../components/VideoModal/ModalTwo";
import CircleLoader from "../../../components/Loader/CircleLoader";
import Hls from "hls.js";
import Control from "../../../components/Control";

const CourseContent = ({ navigate, theme, decoded, dispatch }) => {
  const [activeId, setActiveId] = useState(false);
  const [activeIdTrailer, setActiveIdTrailer] = useState(false);
  const playerRef = useRef(null);
  const [courseContentData, setCourseContentData] = useState([]);
  const courseId = useSelector((state) => state?.courseId);
  const selectLang = useSelector((state) => state.selectLang);

  const [isOpenSec, setIsOpenSec] = useState(false);
  const selectLangJson = useSelector((state) => state?.selectLangJson);
  const [feildLoading, setFeildLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const socket = useContext(SocketContext);
  const [videoURL, setVideoURL] = useState("");
  const [videoTiming, setVideoTiming] = useState(0);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const [isTrailerPlay, setIsTrailerPlay] = useState(false);
  const [volume, setVolume] = useState(0);
  const [videoURL1, setvideoURL1] = useState("");
  const [videoURL2, setvideoURL2] = useState("");
  const [currentURL, setCurrentURL] = useState("");
  const [seek, setSeek] = useState(0);
  const [maxWatchedTime, setMaxWatchedTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [qualities, setQualities] = useState([]);
  const [hlsInstance, setHlsInstance] = useState(null);
  const [currentQuality, setCurrentQuality] = useState("auto");
  const [isDuration, setIsDuration] = useState(0);
  const controlRef = useRef(null); // Using useRef to persist hls instance

  const scrollRef = useRef(null);
  const videoPlayerRef = useRef([]);
  const [isLoaderAdd, setIsLoaderAdd] = useState(false);
  const getCourseContent = async () => {
    setFeildLoading(true);
    let url = `${Course.getCourseContent}content`;
    const formValue = {
      _id: courseId,
      languageId: selectLang,
    };
    try {
      const { data } = await axios.post(url, formValue);
      setCourseContentData(data.data[0]);
      dispatch(setCourseViewId(data.data[0]));
      localStorage.setItem("rainCourseViewId", encryptData(data.data[0]));
      // localStorage.setItem("rainCourseViewId", JSON.stringify(data.data[0]));
      setFeildLoading(false);
    } catch (error) {
      setFeildLoading(false);
      // alert(error.response.data.message);
      console.log(error.response.data.message);
      if (error.response && error.response.status === 400) {
        dispatch(setErrorPop(true));
        dispatch(setErrorPopMsg(error?.response?.data?.message));
      }
    }
  };

  useEffect(() => {
    getCourseContent();
  }, [courseId, selectLang]);
  useEffect(() => {
    // if (tokenID) {
    // socket.emit("token_details_update", { token: tokenID });
    socket.on(
      `add_new_epsoide_${courseId}_${courseContentData?.course_baise_infos?.courseId}`,
      (event) => {
        console.log("event data", event);
      }
    );
  }, [socket]);

  const skeletonBaseColor = theme ? "#1F242F" : "#EDEEF1";
  const skeletonHighlightColor = theme ? "#1F242F" : "#EDEEF1";

  // Media

  const handleVideoClose = (e) => {
    setIsOpen(e);
  };

  const handleMetadatas = (e) => {
    setIsLoaderAdd(false);
    setIsDuration(formatTime(e.target.duration));
    // setIsSamDuration(e.target.duration);
    // setIsLogoAddDuration(Math.floor(e.target.duration));
    const savedVolume =
      parseFloat(localStorage.getItem("rainLearnerVolume")) || 0.5; // Default to 50% if not set
    setVolume(savedVolume);
    videoPlayerRef.current.volume = savedVolume;
  };
  const handleTimeUpdate = () => {
    if (videoPlayerRef.current) {
      const currentTime = videoPlayerRef.current.currentTime || 0;
      const duration = videoPlayerRef.current.duration;
      const seekValue = (currentTime / duration) * 100;
      setCurrentTime(currentTime);
      setSeek(seekValue); // Update the seek state
      // if (duration > 0 && !apiCalled && duration === currentTime) {
      //   setApiCalled((apiCalled) => {
      //     if (!apiCalled) {
      //       debouncedNameFunction.current(currentTime);
      //       return true;
      //     }
      //     return apiCalled;
      //   });
      // }
    }
  };
  const handleVideoEnd = () => {
    // if (currentURL === videoURL1) {
    //   setIsLoaderAdd(true);
    //   setCurrentURL(videoURL2); // Switch to the second video
    //   if (videoPlayerRef.current) {
    //     var playPromise = videoPlayerRef?.current?.play();

    //     if (playPromise !== undefined) {
    //       playPromise
    //         .then((_) => {
    //           // videoPlayerRef?.current?.play();
    //           setIsPlaying(true);
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    //   }
    //   // setIsPaused(false); // Autoplay the next video
    // } else {
    // Optionally reset to the first video or handle end of playlist
    console.log("End of playlist");
    setIsPlaying(false);
    // }
  };

  // Media Control

  const handlePlay = () => {
    var playPromise = videoPlayerRef?.current?.play();

    if (playPromise !== undefined) {
      playPromise
        .then((_) => {
          // videoPlayerRef?.current?.play();
          setIsPlaying(true);
          setIsLoaderAdd(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePause = () => {
    videoPlayerRef?.current?.pause();
    setIsPlaying(false);
  };

  const volumeChangeHandler = (event) => {
    const newVolume = parseFloat(event.target.value) / 100;
    setVolume(newVolume);
    videoPlayerRef.current.volume = newVolume;
  };

  const toggleMute = () => {
    const newMuteStatus = !isMuted;
    setIsMuted(newMuteStatus);
    videoPlayerRef.current.muted = newMuteStatus;
  };

  const handleSeek = (event) => {
    const newSeekValue = parseInt(event.target.value, 10); // Get the percentage of duration from the slider
    const newSeekTime = (newSeekValue / 100) * videoPlayerRef.current.duration; // Convert percentage to seconds

    videoPlayerRef.current.currentTime = newSeekTime;
    setSeek(newSeekValue); // Update the slider position

    setIsDuration(formatTime(videoPlayerRef.current.duration)); // Optional: If you need to display the duration
  };

  const handleQualityChange = (levelIndex) => {
    if (hlsInstance) {
      const currentTime = videoPlayerRef.current.currentTime || 0;
      const isPaused = videoPlayerRef.current.paused;
      hlsInstance.currentLevel = levelIndex;
      hlsInstance.startLoad();
      hlsInstance.once(Hls.Events.FRAG_LOADED, () => {
        videoPlayerRef.current.currentTime = currentTime;
        if (!isPaused) {
          videoPlayerRef?.current?.play();
        }
      });
      setCurrentQuality(levelIndex);
    }
  };

  const toggleFullScreen = () => {
    const videoElement = controlRef.current.closest(".media-class-box"); // Reference to your video player

    if (!document.fullscreenElement) {
      if (videoElement.requestFullscreen) {
        setIsFullScreen(true);
        videoElement.requestFullscreen(); // Standard way to request fullscreen
      } else if (videoElement.webkitRequestFullscreen) {
        setIsFullScreen(true);
        videoElement.webkitRequestFullscreen(); // Safari
      } else if (videoElement.msRequestFullscreen) {
        setIsFullScreen(true);
        videoElement.msRequestFullscreen(); // IE11
      }
    } else {
      if (document.exitFullscreen) {
        setIsFullScreen(false);
        document.exitFullscreen(); // Exit fullscreen
      } else if (document.webkitExitFullscreen) {
        setIsFullScreen(false);
        document.webkitExitFullscreen(); // Safari
      } else if (document.msExitFullscreen) {
        setIsFullScreen(false);
        document.msExitFullscreen(); // IE11
      }
    }
  };
  return (
    <>
      <CDS.DetailContainer themeColor={theme}>
        {/* <CourseView
          courseId={courseId}
          navigate={navigate}
          theme={theme}
          selectLangJson={selectLangJson}
        /> */}
        <CDS.DetailCon themeColor={theme}>
          <CDS.DetailInnerCon themeColor={theme}>
            <CDS.AboutBox themeColor={theme}>
              {feildLoading ? (
                <Skeleton
                  width={430}
                  baseColor={skeletonBaseColor}
                  highlightColor={skeletonHighlightColor}
                  height={28}
                  style={{ borderRadius: "5px" }}
                />
              ) : (
                <p className="about-title">
                  {selectLangJson.about_this_course} :
                </p>
              )}
              {feildLoading ? (
                <div style={{ width: "100%" }}>
                  <Skeleton
                    width={"!00%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={28}
                    style={{ borderRadius: "5px", margin: "1rem 0" }}
                  />
                </div>
              ) : (
                <p className="about-desc">
                  {get(
                    courseContentData,
                    "course_baise_infos.baiseDescription",
                    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  )}
                </p>
              )}
            </CDS.AboutBox>
            {feildLoading ? (
              <Skeleton
                width={430}
                baseColor={skeletonBaseColor}
                highlightColor={skeletonHighlightColor}
                height={28}
                style={{ borderRadius: "5px", margin: "1rem 0" }}
              />
            ) : (
              <CDS.EpisodeTitle themeColor={theme}>
                {selectLangJson.trailer}
              </CDS.EpisodeTitle>
            )}

            <CDS.EpisodeContainer>
              {feildLoading ? (
                <div style={{ width: "100%" }}>
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                </div>
              ) : (
                get(courseContentData, "course_baise_infos.trailers", [])?.map(
                  (val, ind) => {
                    return (
                      <CDS.VideoRow key={ind} themeColor={theme}>
                        <CDS.FileRow>
                          <CDS.VideoImageBox>
                            <img
                              src={get(
                                courseContentData,
                                "course_baise_infos.thumbnailImage",
                                ""
                              )}
                              alt=""
                            />
                            <img
                              className="play-butttonon"
                              src={playButton}
                              onClick={() => {
                                setvideoURL1(val.convert_url);
                                setvideoURL2(val.convert_url);
                                setCurrentURL(val.convert_url);
                                setIsOpen(true);
                                setIsPlaying(true);
                              }}
                              alt=""
                            />
                          </CDS.VideoImageBox>
                          <CDS.VideoContent>
                            <CDS.VideoTitleRow>
                              <CDS.VideoTitle themeColor={theme}>
                                <div>{val.title}</div>
                                <button
                                  onClick={() => {
                                    setActiveIdTrailer((currentActiveId) =>
                                      currentActiveId === val._id ? "" : val._id
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      activeId === val._id
                                        ? theme
                                          ? darkUp
                                          : upArrow
                                        : theme
                                        ? darkDown
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </button>
                              </CDS.VideoTitle>
                            </CDS.VideoTitleRow>
                            <CDS.MinBox themeColor={theme}>
                              {convertTimeFormatMin(val.videoTime)}
                            </CDS.MinBox>
                          </CDS.VideoContent>
                        </CDS.FileRow>
                        {activeIdTrailer === val._id ? (
                          <CDS.TagsBox style={{ marginTop: "0.5rem" }}>
                            <CDS.TagsTitle themeColor={theme}>
                              {selectLangJson.description}
                            </CDS.TagsTitle>
                            <CDS.DescContent themeColor={theme}>
                              {val.description}
                            </CDS.DescContent>
                          </CDS.TagsBox>
                        ) : (
                          ""
                        )}
                      </CDS.VideoRow>
                    );
                  }
                )
              )}
            </CDS.EpisodeContainer>
            {feildLoading ? (
              <Skeleton
                width={430}
                baseColor={skeletonBaseColor}
                highlightColor={skeletonHighlightColor}
                height={28}
                style={{ borderRadius: "5px", margin: "1rem 0" }}
              />
            ) : (
              <CDS.EpisodeTitle themeColor={theme}>
                {selectLangJson.episodes}
              </CDS.EpisodeTitle>
            )}

            <CDS.EpisodeContainer>
              {/* {get(courseContentData, "episode.courseEpisodes", [])?.map((val, ind) => { */}
              {feildLoading ? (
                <div style={{ width: "100%" }}>
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                  <Skeleton
                    width={"100%"}
                    baseColor={skeletonBaseColor}
                    highlightColor={skeletonHighlightColor}
                    height={78}
                    style={{ borderRadius: "5px", marginBottom: "1rem" }}
                  />
                </div>
              ) : (
                get(courseContentData, "course_episodes", [])?.map(
                  (val, ind) => {
                    return (
                      <CDS.VideoRow key={ind} themeColor={theme}>
                        <CDS.FileRow>
                          <CDS.VideoImageBox>
                            <img
                              src={get(
                                courseContentData,
                                "course_baise_infos.thumbnailImage",
                                ""
                              )}
                              alt=""
                            />
                          </CDS.VideoImageBox>
                          <CDS.VideoContent>
                            <CDS.VideoTitleRow>
                              <CDS.VideoTitle themeColor={theme}>
                                <div>{val.episodeName}</div>
                                <button
                                  onClick={() => {
                                    setActiveId((currentActiveId) =>
                                      currentActiveId === val._id ? "" : val._id
                                    );
                                  }}
                                >
                                  <img
                                    src={
                                      activeId === val._id
                                        ? theme
                                          ? darkUp
                                          : upArrow
                                        : theme
                                        ? darkDown
                                        : downArrow
                                    }
                                    alt=""
                                  />
                                </button>
                              </CDS.VideoTitle>
                            </CDS.VideoTitleRow>
                            <CDS.MinBox themeColor={theme}>
                              {convertTimeFormatMin(val.videoTime)}
                            </CDS.MinBox>
                          </CDS.VideoContent>
                        </CDS.FileRow>
                        {activeId === val._id ? (
                          <CDS.TagsBox>
                            <CDS.TagsTitle themeColor={theme}>
                              {selectLangJson.tags}
                            </CDS.TagsTitle>
                            <CDS.TagsRow>
                              {val?.courseTags?.map((val) => (
                                <CDS.ComTags themeColor={theme}>
                                  {val}
                                </CDS.ComTags>
                              ))}
                            </CDS.TagsRow>
                          </CDS.TagsBox>
                        ) : (
                          ""
                        )}
                        {activeId === val._id ? (
                          <CDS.TagsBox style={{ marginTop: "0.5rem" }}>
                            <CDS.TagsTitle themeColor={theme}>
                              {selectLangJson.description}
                            </CDS.TagsTitle>
                            <CDS.DescContent themeColor={theme}>
                              {val.description}
                            </CDS.DescContent>
                          </CDS.TagsBox>
                        ) : (
                          ""
                        )}
                        {/* {activeId === val._id ? (
                          <CDS.TagsTitle themeColor={theme}>
                            Assessment Quiz
                          </CDS.TagsTitle>
                        ) : (
                          ""
                        )} */}
                      </CDS.VideoRow>
                    );
                  }
                )
              )}
            </CDS.EpisodeContainer>
          </CDS.DetailInnerCon>
        </CDS.DetailCon>
      </CDS.DetailContainer>
      {/* <VideoModal
        isOpen={isOpen}
        isClose={handleVideoClose}
        component={
          <VideoJS
            options={videoJsOptions}
            onReady={handlePlayerReady}
            handleOpen={setIsOpenSec}
            handleTiming={setVideoTiming}
            timing={videoTiming}
            videoURL={videoURL}
          />
        }
      /> */}
      <ModalTwo
        isOpen={isOpen}
        isClose={handleVideoClose}
        handleTrailerPlay={setIsTrailerPlay}
        theme={theme}
        component={
          <SS.VideoContainerSo
            isFullScreen={isFullScreen}
            className="media-class-box"
            themeColor={theme}
          >
            {/* {isTrailerPlay ? ( */}
            <SS.MediaContainer>
              {isLoaderAdd ? <CircleLoader themeColor={theme} /> : ""}
              <ReactHlsPlayer
                className="video-class-element"
                style={{
                  display: isLoaderAdd ? "none" : "flex",
                }}
                // ref={(el) => setVideoRef(el, index)}
                playerRef={videoPlayerRef}
                src={currentURL}
                muted={isMuted}
                autoPlay={true}
                volume={volume}
                onLoadedMetadata={handleMetadatas}
                onEnded={handleVideoEnd}
                onTimeUpdate={handleTimeUpdate}
                width={isFullScreen ? "100%" : 800}
                height={isFullScreen ? "100%" : 450}
                controls={false}
              />
              <Control
                isCourseConent={true}
                onPlay={handlePlay}
                onPause={handlePause}
                playing={isPlaying}
                onVolumeChange={volumeChangeHandler}
                volume={volume * 100}
                handleMute={toggleMute}
                isMuted={isMuted}
                seek={seek}
                handleSeekChange={handleSeek}
                duration={isDuration}
                currentTime={formatTime(currentTime)}
                qualities={qualities}
                handleQualityChange={handleQualityChange}
                currentQuality={currentQuality}
                toggleFullScreen={toggleFullScreen}
                controlRef={controlRef}
                isFullScreen={isFullScreen}
              />
            </SS.MediaContainer>
          </SS.VideoContainerSo>
        }
      />
    </>
  );
};

export default CourseContent;
